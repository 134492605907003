import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ErrorPage } from '../../pages/Shared';
import { CustomerSettingsPage } from '../../pages/App/Customer/Settings';

export default function CustomerRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path='/settings' element={<CustomerSettingsPage />} />
      <Route path='*' element={<ErrorPage />} />
    </Routes>
  );
}
