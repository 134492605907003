import React from 'react';

type Props = {
  text: string;
};

export default function GrayTitle({ text }: Props): JSX.Element {
  return (
    <div className='w-full h-auto text-xl text-documentsGreyTextHeader'>
      {text}
    </div>
  );
}
