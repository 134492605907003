import { db } from '../../../../../firebase/config';
import { collection, getDocs } from 'firebase/firestore';
import {
  StandardEstimateDataForm,
  StandardEstimateDTO,
} from '../../../../../types';
import {
  contractorDocumentsCollection,
  contractsSubCollection,
} from '../../../../../shared/constants';

export const GetAllContracts = async (userId: string) => {
  const response: StandardEstimateDTO[] = [];
  //  sub-collection path: contractor_documents/${auth.currentUser?.uid}/contracts
  const querySnapshot = await getDocs(
    collection(
      db,
      `${contractorDocumentsCollection}/${userId}/${contractsSubCollection}`
    )
  );

  querySnapshot.forEach((doc) => {
    // only add valid documents
    if (doc?.id) {
      const docData = doc.data() as StandardEstimateDataForm;
      const currentDoc: StandardEstimateDTO = { id: doc.id, data: docData };
      response.push(currentDoc);
    }
  });

  return response;
};
