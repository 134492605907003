import React from 'react';
import AccountReset from './AccountReset/AccountReset';
import HomeAddress from './HomeAddress/HomeAddress';
import PersonalInformation from './PersonalInformation/PersonalInformation';
import Footer from '../../../../components/Footer/Footer';
import { NavbarCustomer } from '../../../../components';

export default function CustomerSettingsPage(): JSX.Element {
  /* INTERNAL COMPONENTS */
  type WidgetContainerProps = {
    children?: React.ReactNode;
  };

  const WidgetContainer: React.FC<WidgetContainerProps> = ({ children }) => {
    return (
      <div className='flex flex-col w-full h-full bg-white rounded-md border-[1px] border-[#E5E7EB] shadow'>
        <div className='profile-widget-container flex flex-col mx-4 mt-4 mb-8 md:m-8'>
          {children}
        </div>
      </div>
    );
  };
  /* END INTERNAL COMPONENTS */
  return (
    <div id='customer-settings-page' className='w-full h-screen flex flex-col'>
      <NavbarCustomer />
      <main className='flex justify-center pt-16 md:pt-24 pb-8 px-4 md:px-8'>
        <div className='flex flex-col w-[90%] max-w-[800px] gap-8'>
          {/* CONTACT INFORMATION */}
          <WidgetContainer>
            <PersonalInformation />
          </WidgetContainer>
          {/* Password */}
          <WidgetContainer>
            <AccountReset />
          </WidgetContainer>
          {/* HOME ADDRESS */}
          <WidgetContainer>
            <HomeAddress />
          </WidgetContainer>
        </div>
      </main>
      <Footer />
    </div>
  );
}
