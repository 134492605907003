import React, { useEffect, useState } from 'react';
import {
  Footer,
  FormContainer,
  Navbar,
  NavbarContractor,
} from '../../../components';
import { PricingCard } from './components/PricingCard/PricingCard';
import { PricingPlansHeader } from './components/PricingPlansHeader/PricingPlansHeader';
import { useAuthContext } from '../../../state/auth-context';

export function PricingPlansPage(): JSX.Element {
  const [navbarType, setNavbarType] = useState('');
  const {
    authState: { authUser, userInfo },
  } = useAuthContext();

  const plans = [
    {
      name: 'Basic',
      price: '$0/month', // this string will be seperated by forward slash to get the amount and frequency
      features: [
        'All your contracts in one place',
        'Share contracts with customers via email',
        'Mobile Access',
      ],
    },
    {
      name: 'Standard',
      price: '$5/month', // this string will be seperated by forward slash to get the amount and frequency
      features: [
        'Everything in basic',
        'Access to 20 contracts per month',
        'More features to come',
      ],
    },
    {
      name: 'Ultimate',
      price: '$15/month', // this string will be seperated by forward slash to get the amount and frequency
      features: [
        'Everything in basic',
        'Access to Unlimited contracts per month',
        'More features to come',
      ],
    },
  ];

  /*****************************/
  /* USE EFFECTS */
  /****************************/
  useEffect(() => {
    if (authUser && userInfo) {
      switch (userInfo.role) {
        case 'CONTRACTOR':
          setNavbarType('CONTRACTOR');
          break;
        default:
          setNavbarType('');
          break;
      }
    } else {
      setNavbarType('');
    }
  }, [authUser, userInfo]);
  /* END - USE EFFECTS */

  /* pricing plans can be uses for auth and non-auth users */
  return (
    <div id='pricing-plans-page' className='w-full h-screen flex flex-col'>
      {navbarType == 'CONTRACTOR' && <NavbarContractor />}
      {navbarType == '' && <Navbar />}
      <main className='flex flex-col px-4 md:px-8 pt-20 pb-12 items-center gap-20'>
        <PricingPlansHeader />
        <div className='grid md2:grid-cols-2 lg2:grid-cols-3 items-center gap-12'>
          {plans.map((plan, index) => (
            <FormContainer key={index}>
              <PricingCard {...plan} />
            </FormContainer>
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
}
