import React from 'react';

interface FormErrorElementContainerProps {
  inputErrorMsg: string;
}

export function FormErrorElementContainer({
  inputErrorMsg,
}: FormErrorElementContainerProps) {
  return <p className='text-base text-red-500 mt-2'>{inputErrorMsg}</p>;
}
