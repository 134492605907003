import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuthContext } from '../state/auth-context';
import { LoadingSpinner } from './shared/LoadingSpinner';

export const PublicRoute = (): React.ReactElement | null => {
  //export const PublicRoute: React.FC = () => {
  const location = useLocation();
  const {
    authState: { authUser, userRole },
  } = useAuthContext();

  /* complete page refresh - auth context values will be set to initialState */
  if (userRole === null) {
    return <LoadingSpinner />;
  }

  // navigate to landing page if user is authenticated
  if (authUser) {
    return <Navigate to='/' replace state={{ from: location }} />;
  }

  // load login related route when user is not authenticated
  return <Outlet />;
};
