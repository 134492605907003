import React, { useEffect, useState } from 'react';
import { ContractorInfo } from '../../../../../../types';
import {
  HeaderAndContentSkeleton,
  StatusMessageError,
} from '../../../../../../components';
import { GetContractorInformation } from '../../ContractorSettingsPageService';
import { CompanyInformationForm } from './CompanyInformationForm';
import { auth } from '../../../../../../firebase/config';

export function CompanyInformation(): JSX.Element {
  const [errorCI, setErrorCI] = useState('');
  const [loadingCI, setLoadingCI] = useState(true);
  const [contractorInfo, setContractorInfo] = useState<ContractorInfo | null>(
    null
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user !== null) {
        // fetch company information
        GetContractorInformation(user.uid)
          .then((contractorInfoObj) => {
            setContractorInfo(contractorInfoObj);
          })
          .catch(() => {
            setErrorCI('An error occurred while fetching company information');
          })
          .finally(() => {
            setLoadingCI(false);
          });
      }
    });

    return unsubscribe;
  }, []);

  /* Internal Components  */
  function CompanyInformationHeader() {
    return (
      <>
        {/* Header */}
        <div className='flex flex-col'>
          {/* Title */}
          <div className='text-2xl font-bold'>
            <h1>Company Information</h1>
          </div>
        </div>
        {/* Note to contractors */}
        <div className='flex flex-row gap-1'>
          <div className='flex flex-col shrink-0 mt-[2px]'>
            <img
              className='w-[20px] h-[20px]'
              src='/assets/shared/icons/ExclamationCircle.svg'
              alt='exclamation icon'
            />
          </div>
          <div className='flex flex-col'>
            <span className='text-[#676d73] opacity-80'>
              Note: This information will be used in your invoices.
            </span>
          </div>
        </div>
      </>
    );
  }
  /* END Internal Components  */

  return (
    <div className='flex flex-col gap-4'>
      <CompanyInformationHeader />
      {/* loading skeleton */}
      {!contractorInfo && loadingCI && !errorCI && <HeaderAndContentSkeleton />}
      {/* error message */}
      {!contractorInfo && !loadingCI && errorCI && (
        <StatusMessageError message='An error occured. Please try again later.' />
      )}
      {/* form */}
      {contractorInfo && !loadingCI && !errorCI && (
        <CompanyInformationForm contractorInfo={contractorInfo} />
      )}
    </div>
  );
}
