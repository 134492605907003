import React from 'react';
import NavbarContainer from '../shared/NavbarContainer/NavbarContainer';
import NavbarContentContractor from '../shared/NavbarContent/Contractor/NavbarContentContractor';

export default function NavbarContractor(): JSX.Element {
  return (
    <NavbarContainer>
      <NavbarContentContractor />
    </NavbarContainer>
  );
}
