import React, { useState } from 'react';
import {
  FormContainer,
  InputWithBottomBorder,
  GrayTitle,
  BlackDivider,
  WhiteRoundedButtonWithLabel,
} from '../../../../../../../components';
import { dateToday } from '../../../../../../../shared/util';
import {
  ContractorInfo,
  StandardEstimateContractType,
  StandardEstimateDataForm,
} from '../../../../../../../types';
import { Control, useFieldArray, useForm, useWatch } from 'react-hook-form';
import {
  CalculateSubTotalAmount,
  CalculateTaxAmount,
  CalculateTotalAmount,
  createContract,
} from '../../../service/TemplatesService';
import { toast, ToastContainer } from 'react-toastify';

interface StandardEstimateFormProps {
  contractorInfo: ContractorInfo | null;
}

export function StandardEstimateForm({
  contractorInfo,
}: StandardEstimateFormProps): JSX.Element {
  const requiredPrompt = 'Required.';
  const [isDocumentEditMode, setIsDocumentEditMode] = useState(true);
  const {
    register,
    trigger,
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<StandardEstimateDataForm>({
    mode: 'onChange',
    defaultValues: {
      company: {
        name: contractorInfo?.company?.name || '',
        address: {
          street: contractorInfo?.company?.address?.street || '',
          city: contractorInfo?.company?.address?.city || '',
          state: contractorInfo?.company?.address?.state || '',
          zipcode: contractorInfo?.company?.address?.zipcode || '',
        },
        contact: {
          phoneNumber: contractorInfo?.company?.contact?.phoneNumber || '',
          email: contractorInfo?.company?.contact?.email || '',
        },
      },
      costs: [{ description: 'Description', quantity: 0, price: 0 }],
    },
  });

  /* form listeners for inputs used in preview mode */
  const { fields, append, remove } = useFieldArray({
    name: 'costs',
    control,
  });

  const jobDetailsVal = useWatch({
    name: 'jobDetails',
    control,
  });

  const companyName = useWatch({
    name: 'company.name',
    control,
  });

  const companyEmail = useWatch({
    name: 'company.contact.email',
    control,
  });

  const companyStreet = useWatch({
    name: 'company.address.street',
    control,
  });

  const companyCity = useWatch({
    name: 'company.address.city',
    control,
  });

  const companyState = useWatch({
    name: 'company.address.state',
    control,
  });

  const companyZipcode = useWatch({
    name: 'company.address.zipcode',
    control,
  });

  const companyPhoneNumber = useWatch({
    name: 'company.contact.phoneNumber',
    control,
  });

  const customerFirstName = useWatch({
    name: 'customer.firstName',
    control,
  });

  const customerLastName = useWatch({
    name: 'customer.lastName',
    control,
  });

  const customerStreet = useWatch({
    name: 'customer.address.street',
    control,
  });

  const customerCity = useWatch({
    name: 'customer.address.city',
    control,
  });

  const customerState = useWatch({
    name: 'customer.address.state',
    control,
  });

  const customerZipcode = useWatch({
    name: 'customer.address.zipcode',
    control,
  });

  const customerEmail = useWatch({
    name: 'customer.contact.email',
    control,
  });

  const customerPhoneNumber = useWatch({
    name: 'customer.contact.phoneNumber',
    control,
  });

  const costsArr = useWatch({
    name: 'costs',
    control,
  });

  const summaryTax = useWatch({
    name: 'taxPercent',
    control,
  });
  /* END -- form listeners for inputs used in preview mode */

  const onEstimateSubmit = handleSubmit(async (data) => {
    // start loading toast before saving document
    const loadingToastForSaveEstimateBtnClick =
      toast.loading('Saving estimate...');

    // create contract by passing contract data and contract type
    const documentCreated = await createContract(
      data,
      StandardEstimateContractType
    );

    // FORCE to close toast
    toast.dismiss(loadingToastForSaveEstimateBtnClick);

    // not saved in db successfully - show error
    if (!documentCreated) {
      toast.error(
        'Error when saving estimate. Please try again in a couple of minutes.'
      );
      return;
    }

    toast.success('Estimate saved successfully.');
  });

  /* Internal Component - SubTotal */
  const EstimateSummary = ({
    control,
  }: {
    control: Control<StandardEstimateDataForm>;
  }) => {
    const formValues = useWatch({
      name: 'costs',
      control,
    });
    const taxVal = useWatch({
      name: 'taxPercent',
      control,
    });
    /* Subtotal will round up to 2 decimal places
     * loop through all values in array and return final summed up amount
     */
    const subTotalAmount = CalculateSubTotalAmount(formValues);
    const taxAmount = CalculateTaxAmount(taxVal, subTotalAmount);
    const totalAmount = CalculateTotalAmount(taxAmount, subTotalAmount);

    return (
      <div className='flex flex-col gap-2'>
        {/* sub total */}
        {taxAmount && taxAmount > 0 && (
          <div className='flex flex-col xs2:flex-row justify-between'>
            <span className='text-white text-lg'>Sub Total</span>
            <span className='text-white text-lg'>${subTotalAmount}</span>
          </div>
        )}
        {/* tax */}
        {taxAmount && taxAmount > 0 && (
          <div className='flex flex-col xs2:flex-row justify-between'>
            <span className='text-white text-lg'>Tax</span>
            <span className='text-white text-lg'>${taxAmount}</span>
          </div>
        )}
        {/* total */}
        <div className='flex flex-col xs2:flex-row justify-between'>
          <span className='text-white text-lg'>Total</span>
          <span className='text-white text-lg'>{totalAmount}</span>
        </div>
      </div>
    );
  };
  /* END Internal Component - SubTotal */

  /* Internal Component - EditAndPreviewToggle  */
  function handlePreviewToggleClick(): void {
    setIsDocumentEditMode(!isDocumentEditMode);
  }

  function handleEditToggleClick(): void {
    setIsDocumentEditMode(!isDocumentEditMode);
    // trigger validation for inputs
    trigger();
  }

  function EditAndPreviewToggle() {
    return (
      <div className='bg-gray-200 h-[50px] w-[160px] flex flex-row mt-[10px] rounded-full border-[1px] border-gray-400 shadow-md'>
        <button
          className={
            'flex-1 px-2 ' +
            (isDocumentEditMode
              ? ' bg-white rounded-full border-[1px] border-gray-400 my-[-2px] ml-[-1px] transition ease-in-out'
              : '')
          }
          onClick={handleEditToggleClick}
          disabled={isDocumentEditMode}
        >
          Edit
        </button>
        <button
          className={
            'flex-1 px-2 ' +
            (isDocumentEditMode
              ? ''
              : ' bg-white rounded-full border-[1px] border-gray-400 my-[-2px] mr-[-1px] transition ease-in-out')
          }
          onClick={handlePreviewToggleClick}
          disabled={!isDocumentEditMode}
        >
          Preview
        </button>
      </div>
    );
  }

  interface PreviewElementContainerProps {
    headerText: string;
    valueText: string;
  }

  function PreviewElementContainer({
    headerText,
    valueText,
  }: PreviewElementContainerProps) {
    return (
      <div className='flex flex-col'>
        <div className='font-medium'>{headerText}</div>
        <div className='break-words'>{valueText}</div>
      </div>
    );
  }

  interface FormErrorElementContainerProps {
    inputErrorMsg: string;
  }

  function FormErrorElementContainer({
    inputErrorMsg,
  }: FormErrorElementContainerProps) {
    return <p className='text-base text-red-500 mt-2'>{inputErrorMsg}</p>;
  }
  /* END Internal Component - EditAndPreviewToggle  */

  return (
    <form
      className='flex flex-col w-[90%] max-w-[800px] gap-8'
      onSubmit={onEstimateSubmit}
    >
      <ToastContainer position={'top-center'} />
      {/* Preview Toggle, Delete, Save Button Row Container */}
      <div className='flex flex-col sm:flex-row justify-end sm:gap-12'>
        {/* hide buttons on preview */}
        {isDocumentEditMode && (
          <div className='flex flex-row justify-end gap-4 xs:gap-8 sm:gap-12 '>
            {/* Reset Form Button -- only show on edit mode */}
            <WhiteRoundedButtonWithLabel
              src={'/assets/shared/icons/x-icon.png'}
              label={'Clear'}
              type={'reset'}
              onClick={() =>
                reset({
                  jobDetails: '',
                  company: {
                    name: '',
                    address: {
                      street: '',
                      city: '',
                      state: '',
                      zipcode: '',
                    },
                    contact: {
                      phoneNumber: '',
                      email: '',
                    },
                  },
                  customer: {
                    firstName: '',
                    lastName: '',
                    address: {
                      street: '',
                      city: '',
                      state: '',
                      zipcode: '',
                    },
                    contact: {
                      phoneNumber: '',
                      email: '',
                    },
                  },
                  costs: [
                    { description: 'Description', quantity: 0, price: 0 },
                  ],
                })
              }
            />
            {/* Save Button */}
            <WhiteRoundedButtonWithLabel
              src={'/assets/shared/icons/folder-icon.png'}
              label={'Save'}
              type='submit'
            />
          </div>
        )}
        <div className='flex justify-center'>
          <EditAndPreviewToggle />
        </div>
      </div>
      {/* note to user on document preview */}
      {!isDocumentEditMode && (
        <div className='flex flex-row gap-1 justify-center mx-2'>
          <div className='flex shrink-0 mt-[2px]'>
            <img
              className='w-[20px] h-[20px]'
              src='/assets/shared/icons/ExclamationCircle.svg'
              alt='exclamation icon'
            />
          </div>
          <div className='flex flex-col'>
            <span className='text-[#676d73] opacity-80'>
              Note: This is a preview of what will be emailed to your customer.
            </span>
          </div>
        </div>
      )}
      {/* Estimate Form */}
      <FormContainer>
        <div className='flex flex-col gap-8'>
          {/* DATE */}
          <div className='flex justify-end text-sm'>
            <span className='font-semibold mr-1'>Invoice Date: </span>
            {dateToday}
          </div>
          {/************* MANDATORY INPUT ***************/}
          {/* JOB DETAILS */}
          <div className='flex flex-col'>
            <GrayTitle text={'Job Details'} />
            {/* on edit mode show input */}
            <InputWithBottomBorder
              // override child classes
              className={
                isDocumentEditMode
                  ? 'w-full px-2 py-2 text-base rounded-none border-b-2 border-[slookuLightGreyBackground] focus:outline-none focus:border-blue-400'
                  : ' hidden '
              }
              placeholder={'Ex: entire roof replacement'}
              type='text'
              aria-label={'Input for Job Details'}
              {...register('jobDetails', {
                required: requiredPrompt,
              })}
            />
            {/* on edit mode show error for mandatory property */}
            {isDocumentEditMode && errors.jobDetails?.message && (
              <FormErrorElementContainer
                inputErrorMsg={errors.jobDetails?.message}
              />
            )}
            {/* on preview mode show property */}
            {!isDocumentEditMode &&
              !errors.jobDetails?.message &&
              jobDetailsVal && (
                <div className='flex flex-col'>
                  <div className='break-words'>{jobDetailsVal}</div>
                </div>
              )}
          </div>
          {/************* END - MANDATORY INPUT ***************/}
          {/* FROM TO Columns - Customer Contractor Info */}
          <div className='flex flex-col md:flex-row gap-8 md:gap-40'>
            {/* FROM Column */}
            <div className='flex-1'>
              <div className='flex flex-col gap-4 max-w-[100%] w-[400px] md:w-full'>
                <GrayTitle text='From' />
                {/************* MANDATORY INPUT ***************/}
                {/* on edit mode show input */}
                <div className='flex flex-col'>
                  <InputWithBottomBorder
                    // override child classes
                    className={
                      isDocumentEditMode
                        ? 'w-full px-2 py-2 text-base rounded-none border-b-2 border-[slookuLightGreyBackground] focus:outline-none focus:border-blue-400'
                        : ' hidden '
                    }
                    placeholder={'Company Name'}
                    type='text'
                    aria-label={'Input for Company Name'}
                    {...register('company.name', {
                      required: requiredPrompt,
                    })}
                  />
                  {/* on edit mode show error for mandatory property */}
                  {isDocumentEditMode && errors.company?.name?.message && (
                    <FormErrorElementContainer
                      inputErrorMsg={errors.company?.name?.message}
                    />
                  )}
                </div>
                {/* on preview mode show property */}
                {!isDocumentEditMode &&
                  !errors.company?.name?.message &&
                  companyName && (
                    <PreviewElementContainer
                      headerText={'Company Name'}
                      valueText={companyName}
                    />
                  )}
                {/************* END - MANDATORY INPUT ***************/}
                {/* company street */}
                {isDocumentEditMode && (
                  <InputWithBottomBorder
                    placeholder={'Street'}
                    type='text'
                    aria-label={'Input for Street'}
                    {...register('company.address.street')}
                  />
                )}
                {!isDocumentEditMode && companyStreet && (
                  <div className='flex flex-col mt-2'>
                    <div className='font-medium'>Street</div>
                    <div>{companyStreet}</div>
                  </div>
                )}
                {/* company city */}
                {isDocumentEditMode && (
                  <InputWithBottomBorder
                    placeholder={'City'}
                    type='text'
                    aria-label={'Input for City'}
                    {...register('company.address.city')}
                  />
                )}
                {!isDocumentEditMode && companyCity && (
                  <div className='flex flex-col mt-2'>
                    <div className='font-medium'>City</div>
                    <div>{companyCity}</div>
                  </div>
                )}
                {/* company state */}
                {isDocumentEditMode && (
                  <InputWithBottomBorder
                    placeholder={'State'}
                    type='text'
                    aria-label={'Input for State'}
                    {...register('company.address.state')}
                  />
                )}
                {!isDocumentEditMode && companyState && (
                  <div className='flex flex-col mt-2'>
                    <div className='font-medium'>State</div>
                    <div>{companyState}</div>
                  </div>
                )}
                {/* company zipcode */}
                {isDocumentEditMode && (
                  <InputWithBottomBorder
                    placeholder={'Zipcode'}
                    type='text'
                    aria-label={'Input for Zipcode'}
                    {...register('company.address.zipcode')}
                  />
                )}
                {!isDocumentEditMode && companyZipcode && (
                  <div className='flex flex-col mt-2'>
                    <div className='font-medium'>Zipcode</div>
                    <div>{companyZipcode}</div>
                  </div>
                )}
                {/* company phone number */}
                {/************* MANDATORY INPUT ***************/}
                {/* on edit mode show input */}
                <div className='flex flex-col'>
                  <InputWithBottomBorder
                    // override child classes
                    className={
                      isDocumentEditMode
                        ? 'w-full px-2 py-2 text-base rounded-none border-b-2 border-[slookuLightGreyBackground] focus:outline-none focus:border-blue-400'
                        : ' hidden '
                    }
                    placeholder={'Phone Number'}
                    type='text'
                    aria-label={'Input for Phone Number'}
                    {...register('company.contact.phoneNumber', {
                      required: requiredPrompt,
                    })}
                  />
                  {/* on edit mode show error for mandatory property */}
                  {isDocumentEditMode &&
                    errors.company?.contact?.phoneNumber?.message && (
                      <FormErrorElementContainer
                        inputErrorMsg={
                          errors.company?.contact?.phoneNumber?.message
                        }
                      />
                    )}
                </div>
                {/* on preview mode show property */}
                {!isDocumentEditMode &&
                  !errors.company?.contact?.phoneNumber?.message &&
                  companyPhoneNumber && (
                    <PreviewElementContainer
                      headerText={'Phone Number'}
                      valueText={companyPhoneNumber}
                    />
                  )}
                {/************* END - MANDATORY INPUT ***************/}
                {/* company email */}
                {/************* MANDATORY INPUT ***************/}
                {/* on edit mode show input */}
                <div className='flex flex-col'>
                  <InputWithBottomBorder
                    // override child classes
                    className={
                      isDocumentEditMode
                        ? 'w-full px-2 py-2 text-base rounded-none border-b-2 border-[slookuLightGreyBackground] focus:outline-none focus:border-blue-400'
                        : ' hidden '
                    }
                    placeholder={'Email'}
                    type='text'
                    aria-label={'Input for Email'}
                    {...register('company.contact.email', {
                      required: requiredPrompt,
                    })}
                  />
                  {/* on edit mode show error for mandatory property */}
                  {isDocumentEditMode &&
                    errors.company?.contact?.email?.message && (
                      <FormErrorElementContainer
                        inputErrorMsg={errors.company?.contact?.email?.message}
                      />
                    )}
                </div>
                {/* on preview mode show property */}
                {!isDocumentEditMode &&
                  !errors.company?.contact?.email?.message &&
                  companyEmail && (
                    <PreviewElementContainer
                      headerText={'Email'}
                      valueText={companyEmail}
                    />
                  )}
                {/************* END - MANDATORY INPUT ***************/}
              </div>
            </div>
            {/* TO Column */}
            <div className='flex-1'>
              <div className='flex flex-col gap-4 max-w-[100%] w-[400px] md:w-full'>
                <GrayTitle text='To' />
                {/* customer first name */}
                {/************* MANDATORY INPUT ***************/}
                {/* on edit mode show input */}
                <div className='flex flex-col'>
                  <InputWithBottomBorder
                    // override child classes
                    className={
                      isDocumentEditMode
                        ? 'w-full px-2 py-2 text-base rounded-none border-b-2 border-[slookuLightGreyBackground] focus:outline-none focus:border-blue-400'
                        : ' hidden '
                    }
                    placeholder={'First Name'}
                    type='text'
                    aria-label={'Input for First Name'}
                    {...register('customer.firstName', {
                      required: requiredPrompt,
                    })}
                  />
                  {/* on edit mode show error for mandatory property */}
                  {isDocumentEditMode &&
                    errors.customer?.firstName?.message && (
                      <FormErrorElementContainer
                        inputErrorMsg={errors.customer?.firstName?.message}
                      />
                    )}
                </div>
                {/* on preview mode show property */}
                {!isDocumentEditMode &&
                  !errors.customer?.firstName?.message &&
                  customerFirstName && (
                    <PreviewElementContainer
                      headerText={'First Name'}
                      valueText={customerFirstName}
                    />
                  )}
                {/************* END - MANDATORY INPUT ***************/}
                {/* customer last name */}
                {isDocumentEditMode && (
                  <InputWithBottomBorder
                    placeholder={'Last Name'}
                    type='text'
                    aria-label={'Input for Last Name'}
                    {...register('customer.lastName')}
                  />
                )}
                {!isDocumentEditMode && customerLastName && (
                  <div className='flex flex-col mt-2'>
                    <div className='font-medium'>Last Name</div>
                    <div className='break-words'>{customerLastName}</div>
                  </div>
                )}
                {/* customer street */}
                {isDocumentEditMode && (
                  <InputWithBottomBorder
                    placeholder={'Street'}
                    type='text'
                    aria-label={'Input for Street'}
                    {...register('customer.address.street')}
                  />
                )}
                {!isDocumentEditMode && customerStreet && (
                  <div className='flex flex-col mt-2'>
                    <div className='font-medium'>Street</div>
                    <div className='break-words'>{customerStreet}</div>
                  </div>
                )}
                {/* customer city */}
                {isDocumentEditMode && (
                  <InputWithBottomBorder
                    placeholder={'City'}
                    type='text'
                    aria-label={'Input for City'}
                    {...register('customer.address.city')}
                  />
                )}
                {!isDocumentEditMode && customerCity && (
                  <div className='flex flex-col mt-2'>
                    <div className='font-medium'>City</div>
                    <div>{customerCity}</div>
                  </div>
                )}
                {/* customer state */}
                {isDocumentEditMode && (
                  <InputWithBottomBorder
                    placeholder={'State'}
                    type='text'
                    aria-label={'Input for State'}
                    {...register('customer.address.state')}
                  />
                )}
                {!isDocumentEditMode && customerState && (
                  <div className='flex flex-col mt-2'>
                    <div className='font-medium'>State</div>
                    <div>{customerState}</div>
                  </div>
                )}
                {/* customer zipcode */}
                {isDocumentEditMode && (
                  <InputWithBottomBorder
                    placeholder={'Zipcode'}
                    type='text'
                    aria-label={'Input for Zipcode'}
                    {...register('customer.address.zipcode')}
                  />
                )}
                {!isDocumentEditMode && customerZipcode && (
                  <div className='flex flex-col mt-2'>
                    <div className='font-medium'>Zipcode</div>
                    <div>{customerZipcode}</div>
                  </div>
                )}
                {/* customer phone number */}
                {/************* MANDATORY INPUT ***************/}
                {/* on edit mode show input */}
                <div className='flex flex-col'>
                  <InputWithBottomBorder
                    // override child classes
                    className={
                      isDocumentEditMode
                        ? 'w-full px-2 py-2 text-base rounded-none border-b-2 border-[slookuLightGreyBackground] focus:outline-none focus:border-blue-400'
                        : ' hidden '
                    }
                    placeholder={'Phone Number'}
                    type='text'
                    aria-label={'Input for Phone Number'}
                    {...register('customer.contact.phoneNumber', {
                      required: requiredPrompt,
                    })}
                  />
                  {/* on edit mode show error for mandatory property */}
                  {isDocumentEditMode &&
                    errors.customer?.contact?.phoneNumber?.message && (
                      <FormErrorElementContainer
                        inputErrorMsg={
                          errors.customer?.contact?.phoneNumber?.message
                        }
                      />
                    )}
                </div>
                {/* on preview mode show property */}
                {!isDocumentEditMode &&
                  !errors.customer?.contact?.phoneNumber?.message &&
                  customerPhoneNumber && (
                    <PreviewElementContainer
                      headerText={'Phone Number'}
                      valueText={customerPhoneNumber}
                    />
                  )}
                {/************* END - MANDATORY INPUT ***************/}
                {/* customer email */}
                {/************* MANDATORY INPUT ***************/}
                {/* on edit mode show input */}
                <div className='flex flex-col'>
                  <InputWithBottomBorder
                    // override child classes
                    className={
                      isDocumentEditMode
                        ? 'w-full px-2 py-2 text-base rounded-none border-b-2 border-[slookuLightGreyBackground] focus:outline-none focus:border-blue-400'
                        : ' hidden '
                    }
                    placeholder={'Email'}
                    type='text'
                    aria-label={'Input for Email'}
                    {...register('customer.contact.email', {
                      required: requiredPrompt,
                    })}
                  />
                  {/* on edit mode show error for mandatory property */}
                  {isDocumentEditMode &&
                    errors.customer?.contact?.email?.message && (
                      <FormErrorElementContainer
                        inputErrorMsg={errors.customer?.contact?.email?.message}
                      />
                    )}
                </div>
                {/* on preview mode show property */}
                {!isDocumentEditMode &&
                  !errors.customer?.contact?.email?.message &&
                  customerEmail && (
                    <PreviewElementContainer
                      headerText={'Email'}
                      valueText={customerEmail}
                    />
                  )}
                {/************* END - MANDATORY INPUT ***************/}
              </div>
            </div>
          </div>
          {/* COST & ESTIMATE SUMMARY - Container */}
          <div className='flex flex-col gap-16'>
            {/* COSTS - Items Breakdown */}
            <div className='flex flex-col gap-4'>
              <GrayTitle text={'Costs'} />
              <BlackDivider />
              {/* COST - Items breakdown & Add Iem */}
              <div className='flex flex-col gap-8'>
                {fields.map((field, index) => {
                  return (
                    <section
                      className={'flex flex-col md2:flex-row justify-between'}
                      key={field.id}
                    >
                      {/* ITEM DESCRIPTION */}
                      <div className='w-full md2:w-[250px] flex flex-col'>
                        <span className='text-documentsGreyTextHeader'>
                          Item
                        </span>
                        {isDocumentEditMode && (
                          <input
                            placeholder='Description'
                            {...register(
                              `costs.${index}.description` as const,
                              {
                                required: requiredPrompt,
                              }
                            )}
                            className='bg-white rounded-md border-[1px] border-[#E5E7EB] px-4 py-2 focus:outline-none focus:border-blue-400'
                          />
                        )}
                        {!isDocumentEditMode &&
                          costsArr[index]?.description && (
                            <div className='flex flex-col mb-2'>
                              <div>{costsArr[index]?.description}</div>
                            </div>
                          )}
                        {isDocumentEditMode &&
                          errors.costs?.[index]?.description?.message && (
                            <div className='flex pt-1'>
                              <p className='text-base text-red-500'>
                                {errors.costs?.[index]?.description?.message}
                              </p>
                            </div>
                          )}
                      </div>
                      {/* ITEM QUANTITY */}
                      <div className='w-full md2:w-[80px] flex flex-col'>
                        <span className='text-documentsGreyTextHeader'>
                          Quantity
                        </span>
                        {isDocumentEditMode && (
                          <input
                            placeholder='0'
                            type='number'
                            {...register(`costs.${index}.quantity` as const, {
                              valueAsNumber: true,
                              required: requiredPrompt,
                            })}
                            className='bg-white rounded-md border-[1px] border-[#E5E7EB] px-4 md2:px-2 py-2 focus:outline-none focus:border-blue-400'
                            defaultValue={field.quantity}
                          />
                        )}
                        {!isDocumentEditMode &&
                          !isNaN(costsArr[index]?.quantity) && (
                            <div className='flex flex-col mb-2'>
                              <div>{costsArr[index]?.quantity}</div>
                            </div>
                          )}
                        {isDocumentEditMode &&
                          errors.costs?.[index]?.quantity?.message && (
                            <div className='flex justify-end'>
                              <p className='text-base text-red-500 pt-1'>
                                {errors.costs?.[index]?.quantity?.message}
                              </p>
                            </div>
                          )}
                      </div>
                      {/* ITEM PRICE */}
                      <div className='w-full md2:w-[150px] flex flex-col'>
                        <span className='text-documentsGreyTextHeader'>
                          Price
                        </span>
                        {isDocumentEditMode && (
                          <input
                            placeholder='0.00'
                            step='.01'
                            type='number'
                            {...register(`costs.${index}.price` as const, {
                              required: requiredPrompt,
                              pattern: {
                                value: /^-?\d+(\.\d{1,2})?$/,
                                message: 'Max two decimal places.',
                              },
                            })}
                            className='bg-white rounded-md border-[1px] border-[#E5E7EB] px-4 py-2 focus:outline-none focus:border-blue-400'
                            defaultValue={field.price}
                          />
                        )}
                        {!isDocumentEditMode &&
                          !isNaN(costsArr[index]?.price) && (
                            <div className='flex flex-col mb-2'>
                              <div>{costsArr[index]?.price}</div>
                            </div>
                          )}
                        {isDocumentEditMode &&
                          errors.costs?.[index]?.price?.message && (
                            <div className='flex justify-end md2:justify-start'>
                              <p className='text-base text-red-500 pt-1'>
                                {errors.costs?.[index]?.price?.message}
                              </p>
                            </div>
                          )}
                      </div>
                      {/* DELETE ITEM BUTTON */}
                      {isDocumentEditMode && (
                        <div className='w-full md2:w-[150px] max-h-[65px] flex flex-col items-center justify-end mt-4 md2:mt-0'>
                          <button
                            className='text-documentsGreyTextHeader w-[65px] flex pb-2'
                            type='button'
                            onClick={() => remove(index)}
                          >
                            DELETE
                          </button>
                        </div>
                      )}
                    </section>
                  );
                })}
                {/* ADD ITEM BUTTON  */}
                {isDocumentEditMode && (
                  <div className='flex flex-col justify-start items-start'>
                    <button
                      className='text-documentsGreyTextHeader text-xl'
                      type='button'
                      onClick={() =>
                        append({
                          description: 'Description',
                          quantity: 0,
                          price: 0,
                        })
                      }
                    >
                      Add Item +
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* Tax - hide tax on preview if user does not need it */}
            {((!isDocumentEditMode && summaryTax) || isDocumentEditMode) && (
              <div className='flex flex-col gap-4'>
                <GrayTitle text={'Tax'} />
                <BlackDivider />
                {/* Tax input */}
                <div className='w-full flex flex-col gap-2'>
                  {/* percent and tax container */}
                  {isDocumentEditMode && (
                    <div className='flex flex-row justify-end items-center gap-2'>
                      <span className='text-documentsGreyTextHeader'>%</span>
                      <input
                        placeholder='0.00'
                        step='.01'
                        type='number'
                        {...register('taxPercent', {
                          pattern: {
                            value: /^\d+(\.\d{1,2})?$/, // for positive and negative values -> value: /^-?\d+(\.\d{1,2})?$/,
                            message:
                              'Must be positive value with two decimal places.',
                          },
                        })}
                        className='w-full md2:w-[150px]  bg-white rounded-md border-[1px] border-[#E5E7EB] px-4 py-2 focus:outline-none focus:border-blue-400'
                      />
                    </div>
                  )}
                  {!isDocumentEditMode && summaryTax && (
                    <div className='flex flex-col mt-2'>
                      <span>% {summaryTax}</span>
                    </div>
                  )}
                  {isDocumentEditMode && errors.taxPercent?.message && (
                    <div className='flex justify-end'>
                      <p className='text-base text-red-500'>
                        {errors.taxPercent?.message}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* ESTIMATE SUMMARY */}
            <div className='flex flex-col items-end'>
              <div className='w-[400px] max-w-[100%] bg-black rounded-md flex flex-col px-8 py-12 gap-4'>
                {/* TITLE */}
                <h1 className='text-white text-xl font-medium'>
                  Estimate Summary
                </h1>
                {/* SUB TOTAL */}
                <EstimateSummary control={control} />
              </div>
            </div>
          </div>
        </div>
      </FormContainer>
    </form>
  );
}
