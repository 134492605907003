import React from 'react';
import { Footer, HomeNavbar } from '../../../components';

export function ErrorPage(): JSX.Element {
  return (
    <div id='global-error-page' className='w-full h-screen flex flex-col'>
      <HomeNavbar />
      <main className='flex-1 flex-col px-4 md:px-8'>
        <div className='w-full h-full flex flex-col gap-6 my-8 justify-center items-center'>
          <img
            alt='icon'
            src={'/assets/shared/icons/triangle-404.png'}
            className='h-[200px] w-[200px] sm:h-[275px] sm:w-[275px] md:h-[350px] md:w-[350px]'
          />
          <span className='text-6xl sm:text-9xl font-light'>404</span>
          <span className='text-2xl sm:text-3xl font-light'>
            Page not found
          </span>
        </div>
      </main>
      <Footer />
    </div>
  );
}
