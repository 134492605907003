import { GrayDivider } from '../../../../../components';
import React from 'react';

interface PricingCardProps {
  name: string;
  price: string;
  features: string[];
}

export function PricingCard({ name, price, features }: PricingCardProps) {
  const [amount, frequency] = price.split('/');

  return (
    <div className='flex flex-col gap-8'>
      {/* plan name and plan pricing */}
      <div className='flex flex-col gap-4'>
        <h1 className='font-light text-5xl md:text-6xl text-[#1E47F6]'>
          {name}
        </h1>
        <div className='flex flex-row gap-1 items-center'>
          <span className='text-5xl md:text-6xl font-normal'>{amount}</span>
          <span className='text-xl md:text-2xl font-light text-gray-500'>
            /{frequency}
          </span>
        </div>
      </div>
      <GrayDivider />
      {/* features */}
      <div>
        <ul className='flex flex-col gap-8'>
          {features.map((feature, index) => (
            <div key={index} className='flex flex-row gap-1 items-center'>
              <img
                className='w-[16px] h-[16px]'
                src={'/assets/shared/icons/neon-blue-mark.png'}
                alt='Plus Icon'
              />
              <li className='text-gray-500 font-light text-base sm:text-lg'>
                {feature}
              </li>
            </div>
          ))}
        </ul>
      </div>
      {/* button */}
      <div className='flex flex-col items-center'>
        <button className='w-[180px] xs:w-[250px] bg-black text-white rounded-3xl p-2 text-lg'>
          Get Started
        </button>
      </div>
    </div>
  );
}
