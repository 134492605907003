import React from 'react';
import { Link } from 'react-router-dom';

export default function SlookuLogoTitle(): JSX.Element {
  return (
    <Link className='flex flex-row items-center gap-2' to={'/'}>
      {/* Logo */}
      <div>
        <img src='/assets/logo/slooku-logo.png' className='w-6 object-cover' />
      </div>
      {/* Slooku */}
      <div>
        <p className='textHeadingColor text-2xl font-bold header-letter-spacing pt-2'>
          Slooku
        </p>
      </div>
    </Link>
  );
}
