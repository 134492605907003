import React, { useEffect, useState } from 'react';
import NavbarContractor from '../../../../components/Navbar/Contractor/NavbarContractor';
import Footer from '../../../../components/Footer/Footer';
import { StandardEstimateDTO } from '../../../../types';
import { auth } from '../../../../firebase/config';
import { GetContractById } from './service/DocumentByIdService';
import StandardEstimateFormById from './StandardEstimateFormById/StandardEstimateFormById';
import { useNavigate } from 'react-router-dom';
import { FormViewLayout } from '../../../../components';

export default function DocumentByIdPage(): JSX.Element {
  const [document, setDocument] = useState<StandardEstimateDTO>();
  const documentIdFromUrl = window.location.pathname.split('/').pop() as string;
  const [documentId] = useState(documentIdFromUrl);
  const navigate = useNavigate();

  // on component load fetch document id from url
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user !== null) {
        GetContractById(user.uid, documentId).then((contract) => {
          /* ***********************************
           * ERROR HANDLING - ROUTE TO DOCUMENTS
           * IN CASE OF:
           * USER PURPOSELY PUT AN INVALID ID IN URL
           * ************************************/
          if (contract?.data) {
            setDocument(contract);
          } else {
            navigate('/contractor/documents');
          }
        });
      }
    });
    return unsubscribe;
  }, []);

  return (
    <div
      id='contractor-document-by-id-page'
      className='w-full h-screen flex flex-col'
    >
      <NavbarContractor />
      <FormViewLayout>
        {document && <StandardEstimateFormById document={document} />}
      </FormViewLayout>
      <Footer />
    </div>
  );
}
