import React from 'react';
import { WhiteRingSpinner } from '../../index';

type Props = {
  disabled: boolean;
  loading: boolean;
  message: string;
};

export default function ButtonWithLoadingSpinner({
  disabled,
  loading,
  message,
}: Props): JSX.Element {
  return (
    <button
      className={
        'w-full h-auto items-center bg-black hover:bg-black/80 text-white font-bold py-2 px-4 rounded-3xl '
      }
      disabled={disabled}
      type='submit'
    >
      {!loading ? message : <WhiteRingSpinner />}
    </button>
  );
}
