import React, { useEffect, useState } from 'react';
import NavbarContractor from '../../../../components/Navbar/Contractor/NavbarContractor';
import Footer from '../../../../components/Footer/Footer';
import { GetAllContracts } from './service/DocumentsService';
import { auth } from '../../../../firebase/config';
import { StandardEstimateDTO } from '../../../../types';
import {
  CalculateTotalAmount,
  getDateFromFirestoreTimestamp,
} from '../../../../shared/util';
import './DocumentsPage.css';
import {
  CardRow,
  CardRowData,
  CardRowDataLink,
  CardRowHeader,
  TableColData,
  TableColDataLink,
  TableColHeader,
  TableRow,
} from './components';
import ContractsGridActionsMenu from './components/ContractsGridActionsMenu/ContractsGridActionsMenu';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../../state/auth-context';
import { toast, ToastContainer } from 'react-toastify';
import { BuildPdfAndSendMail } from '../DocumentById/service/DocumentByIdService';

export default function DocumentsPage(): JSX.Element {
  const [contractList, setContractList] = useState<StandardEstimateDTO[]>();
  const {
    authState: { userInfo },
  } = useAuthContext();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user !== null) {
        GetAllContracts(user.uid).then((data) => {
          setContractList(data);
        });
      }
    });
    return unsubscribe;
  }, []);

  const handleEmailContractClick = async (contractId: string) => {
    // start loading toast before saving document
    const loadingToastForEmailBtnClick = toast.loading(
      'Started sending email...'
    );

    // Proceed with email sending
    const contractorId = userInfo?.id as string;
    const documentSent = await BuildPdfAndSendMail(contractorId, contractId);

    // FORCE to close toast
    toast.dismiss(loadingToastForEmailBtnClick);

    // not updated in db successfully then stop from proceeding with email sending
    if (!documentSent) {
      toast.error(
        'Error when sending email. Please try again in a couple of minutes.'
      );
      return;
    }

    toast.success(
      'Email sent successfully, it should arrive in a couple of minutes.'
    );
  };

  return (
    /* relative is needed to contain actions menu that contains absolute */
    <div
      id='contractor-documents-page'
      className='w-full h-screen relative flex flex-col'
    >
      <NavbarContractor />
      <ToastContainer position={'top-center'} />

      <main className='flex-1 justify-center pt-16 md:pt-24 pb-8 px-4 md:px-8'>
        {/* no documents to show message */}
        {contractList && contractList.length < 1 && (
          <div className='flex flex-col items-center w-full gap-16 mx-2'>
            <img
              className='h-[180px] w-[180px]'
              src='/assets/pages/App/Contractor/Documents/no-documents-icon-2.png'
              alt='no-docs-icon'
            />
            <div className='flex flex-col items-start xs2:items-center gap-4'>
              <h1 className='text-2xl sm:text-3xl font-light'>
                Sorry no documents found!
              </h1>
              <Link to={'/contractor/templates'}>
                <span className='text-lg font-light text-[#509ef6] hover:underline hover:text-[#509ef6]/90'>
                  Click here to browse templates
                </span>
              </Link>
            </div>
          </div>
        )}
        {/* mobile table */}
        {contractList && contractList.length > 0 && (
          <div className='flex flex-col w-full lg2:hidden gap-8 justify-center'>
            {contractList &&
              contractList.map((item, index) => {
                const date = getDateFromFirestoreTimestamp(item.data.createdAt);
                const total = CalculateTotalAmount(
                  item.data.costs,
                  item.data.taxPercent
                );

                return (
                  <div
                    className='flex flex-col w-full border border-slookuGreyContainerPrimary rounded-2xl overflow-hidden shadow font-light'
                    key={index}
                  >
                    {/* actions menu */}
                    <div className='flex flex-row items-center justify-between px-6 border-b border-slookuGreyContainerPrimary'>
                      <div className='text-base font-normal'>Actions</div>
                      <ContractsGridActionsMenu
                        contractId={item.id}
                        onEmailContractClick={handleEmailContractClick}
                      />
                    </div>
                    {/* id */}
                    <CardRow>
                      <CardRowHeader headerText={'Contract Id'} />
                      <CardRowDataLink dataText={item.id} />
                    </CardRow>
                    {/* first name */}
                    <CardRow>
                      <CardRowHeader headerText={'First Name'} />
                      <CardRowData dataText={item.data.customer.firstName} />
                    </CardRow>
                    {/* last name */}
                    <CardRow>
                      <CardRowHeader headerText={'Last Name'} />
                      <CardRowData dataText={item.data.customer.lastName} />
                    </CardRow>
                    {/* total */}
                    <CardRow>
                      <CardRowHeader headerText={'Price'} />
                      <CardRowData dataText={total} />
                    </CardRow>
                    {/* date */}
                    <CardRow>
                      <CardRowHeader headerText={'Date'} />
                      <CardRowData dataText={date} />
                    </CardRow>
                    {/* job details */}
                    <CardRow isLastRow={true}>
                      <CardRowHeader headerText={'Details'} />
                      <CardRowData dataText={item.data.jobDetails} />
                    </CardRow>
                  </div>
                );
              })}
          </div>
        )}
        {/* web table */}
        {contractList && contractList.length > 0 && (
          <div className='contractor-documents-page-table-container hidden lg2:flex w-full border border-slookuGreyContainerPrimary rounded-2xl overflow-hidden shadow'>
            <table className='contractor-documents-page-table min-w-full text-left text-base font-light'>
              <thead className='border-b border-slookuGreyContainerPrimary font-medium'>
                <tr>
                  <TableColHeader headerText={'Contract Id'} />
                  <TableColHeader headerText={'First Name'} />
                  <TableColHeader headerText={'Last Name'} />
                  <TableColHeader headerText={'Price'} />
                  <TableColHeader headerText={'Date'} />
                  <TableColHeader headerText={'Details'} />
                  <TableColHeader headerText={'Actions'} />
                </tr>
              </thead>
              <tbody>
                {contractList &&
                  contractList.map((item, index) => {
                    const date = getDateFromFirestoreTimestamp(
                      item.data.createdAt
                    );
                    const total = CalculateTotalAmount(
                      item.data.costs,
                      item.data.taxPercent
                    );

                    return (
                      <TableRow key={index}>
                        <TableColDataLink dataText={item.id} />
                        <TableColData dataText={item.data.customer.firstName} />
                        <TableColData dataText={item.data.customer.lastName} />
                        <TableColData dataText={total} />
                        <TableColData dataText={date} />
                        <TableColData
                          isLargeColumn={true}
                          dataText={item.data.jobDetails}
                        />
                        <td>
                          <ContractsGridActionsMenu
                            contractId={item.id}
                            onEmailContractClick={handleEmailContractClick}
                          />
                        </td>
                      </TableRow>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
}
