import { auth, db } from '../../../../../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  StandardEstimateDataForm,
  StandardEstimateDTO,
} from '../../../../../types';
import {
  contractorDocumentsCollection,
  contractsSubCollection,
} from '../../../../../shared/constants';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const GetContractById = async (userId: string, contractId: string) => {
  //  sub-collection path: contractor_documents/${auth.currentUser?.uid}/contracts
  const docRef = doc(
    db,
    `${contractorDocumentsCollection}/${userId}/${contractsSubCollection}`,
    contractId
  );
  /* use docSnap.exists() to validate document
   * document will exist because it was extracted from documents grid */
  const docSnap = await getDoc(docRef);

  const docData = docSnap.data() as StandardEstimateDataForm;
  const Doc: StandardEstimateDTO = { id: docSnap.id, data: docData };
  return Doc;
};

export const UpdateContractById = async (
  contractId: string,
  data: StandardEstimateDataForm
) => {
  const docRef = doc(
    db,
    `${contractorDocumentsCollection}/${auth.currentUser?.uid}/${contractsSubCollection}`,
    contractId
  );

  try {
    await updateDoc(docRef, {
      ...data,
    });

    return true;
  } catch (error) {
    // Error updating document invalid contractId or network error
    return false;
  }
};

// generate function called BuildPdfAndSendMail that will take in a contractId and a userId and return a promise
export const BuildPdfAndSendMail = async (
  contractorId: string,
  contractId: string
) => {
  const functions = getFunctions();
  const onBuildPdfAndSendMail = httpsCallable(
    functions,
    'onBuildPdfAndSendMail'
  );

  try {
    await onBuildPdfAndSendMail({
      contractorId: contractorId,
      contractId: contractId,
    });

    return true;
  } catch (error) {
    // Error generating pdf and sending email or network error
    return false;
  }
};
