import React from 'react';
import { Link } from 'react-router-dom';

export function ContractorAccountCard(): JSX.Element {
  return (
    <div className='flex-1 flex-col bg-[#FFE07B] bg-opacity-90 rounded-2xl'>
      <div className='flex flex-col pt-[3.5rem] md:pt-[5.5rem] px-4 pb-12 items-center gap-4'>
        {/* Image */}
        <div>
          <img
            src='/assets/select-account-types/contractor.png'
            alt='contractor'
          ></img>
        </div>
        {/* Title and Description */}
        <div className='flex flex-col text-center gap-4'>
          <div className='flex'>
            <h1 className='text-4xl font-bold'>I&apos;m a Contractor</h1>
          </div>
          <div className='flex flex-col gap-0'>
            <p className='text-md font-bold'>Create this account if you:</p>
            <p className='text-md'>Are a handymen or contractor</p>
            <p className='text-md'>ready to help homeowners.</p>
          </div>
        </div>
        {/* Button */}
        <div className='flex flex-col w-[175px]'>
          <Link to='/sign-up?type=contractor'>
            <button
              type='button'
              className='text-white bg-[#01DF3F] hover:bg-[#01DF3F]/80 font-medium rounded-3xl text-center px-6 py-1.5'
            >
              Create Account
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
