import React from 'react';
import { FaTimes, FaBars } from 'react-icons/fa';

type HamburgerMenuProps = {
  dropDownNavMenuOpen: boolean;
  onClick: () => void;
};

export default function HamburgerMenu({
  dropDownNavMenuOpen,
  onClick,
}: HamburgerMenuProps): JSX.Element {
  return (
    <div className='flex items-center'>
      {/* Desktop - Profile Pic + Burger Nav */}
      <button
        className='hidden md2:flex cursor-pointer pl-2 pr-4 py-2 bg-white border border-[#d0d0d0] rounded-3xl shadow-md'
        type='button'
        onClick={onClick}
      >
        <div className='flex flex-row items-center gap-3'>
          <img
            src='/assets/shared/icons/profile_avatar.svg'
            className='h-7'
            alt='profile'
          />
          {dropDownNavMenuOpen ? (
            <FaTimes className='text-[#737373] h-4' />
          ) : (
            <FaBars className='text-[#737373] h-4' />
          )}
        </div>
      </button>
      {/* Mobile - Burger Nav */}
      <button
        className='flex md2:hidden cursor-pointer p-3 bg-white border border-[#d0d0d0] rounded-full shadow-lg'
        type='button'
        onClick={onClick}
      >
        {dropDownNavMenuOpen ? (
          <FaTimes className='text-[#737373] h-4' />
        ) : (
          <FaBars className='text-[#737373] h-4' />
        )}
      </button>
    </div>
  );
}
