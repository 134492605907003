import React from 'react';
import { IContractorProfilePreviewDTO } from '../../../../data/DTO/IContractorProfilePreviewDTO';
import ContractorProfilePreviewCard from './ContractorProfilePreview/ContractorProfilePreviewCard';

type Props = {
  contractorProfilePreviewList: IContractorProfilePreviewDTO[];
};

function ContractorGrid({ contractorProfilePreviewList }: Props): JSX.Element {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 mt-12'>
      {contractorProfilePreviewList &&
        contractorProfilePreviewList.length &&
        contractorProfilePreviewList.map((contractorProfilePreview, index) => (
          <ContractorProfilePreviewCard
            key={index}
            profilePreview={contractorProfilePreview}
          />
        ))}
    </div>
  );
}

export default ContractorGrid;
