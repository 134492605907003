import React from 'react';

type FormContainerProps = {
  children?: React.ReactNode;
};

export default function FormContainer({
  children,
}: FormContainerProps): JSX.Element {
  return (
    <div className='flex flex-col w-full h-full bg-white rounded-md border-[1px] border-[#E5E7EB] shadow'>
      <div className='flex flex-col mx-4 mt-4 mb-8 md:m-8'>{children}</div>
    </div>
  );
}
