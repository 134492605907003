import React from 'react';

function SaveButton(): JSX.Element {
  return (
    <button
      type='button'
      className='cursor-pointer bg-slookuOrange hover:bg-slookuOrange/80 text-base text-white px-10 py-2 rounded-3xl'
    >
      Save
    </button>
  );
}

export default SaveButton;
