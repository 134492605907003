import React from 'react';
import { FormContainer } from '../../../../../components';
import GrayDivider from '../../../../../components/Divider/Gray/GrayDivider';

type Props = {
  setFormView: () => void;
};
export function TemplatesGrid({ setFormView }: Props): JSX.Element {
  return (
    <main className='flex-1 flex-col justify-center pt-8 md:pt-16 pb-8 px-4 md:px-8'>
      {/* Tab title and Templates Grid - Container */}
      <div className='flex flex-col gap-8'>
        {/* Tab title and description */}
        <div className='flex flex-col gap-4'>
          <div className='w-full flex justify-start text-2xl'>Estimates</div>
          <div className='text-[#6B7280]'>
            Click on a template from the following choices.
          </div>
        </div>
        {/* Estimate Widgets Grid */}
        <div className='flex flex-col items-center justify-center md2:flex-row gap-12 md:gap-20'>
          {/* Standard Estimate */}
          <div
            className='w-[400px] max-w-full cursor-pointer'
            onClick={() => setFormView()}
          >
            <FormContainer>
              <div className='flex flex-col gap-2'>
                <img
                  src='/assets/contractor-estimates/estimate-preview.png'
                  alt='estimate'
                  className='w-full h-auto'
                />
                <GrayDivider />
                <div className='text-lg font-semibold'>Standard Estimate</div>
                <div className='text-[#6B7280]'>
                  Description of work, customer information and list of costs.
                </div>
              </div>
            </FormContainer>
          </div>
        </div>
      </div>
    </main>
  );
}
