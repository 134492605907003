import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer(): JSX.Element {
  const tradeMarkYear = '2023';
  return (
    <footer className='flex mt-4 py-4 px-4 md:px-8'>
      <div className='bg-[#CFD2DC] opacity-75 flex flex-col w-full h-full rounded-2xl'>
        {/* footer-desktop-container */}
        <div className='hidden md:flex flex-col items-center'>
          {/* Navigation Container */}
          <div className='flex items-center p-8'>
            <ul className='flex items-center gap-8'>
              <Link to='/about-us'>
                <li className='text-base text-navTabBlue cursor-pointer'>
                  About Us
                </li>
              </Link>
              <Link to='/terms'>
                <li className='text-base text-navTabBlue cursor-pointer'>
                  Terms of Service
                </li>
              </Link>
              <Link to='/privacy-policy'>
                <li className='text-base text-navTabBlue cursor-pointer'>
                  Privacy Policy
                </li>
              </Link>
              <Link to='/help'>
                <li className='text-base text-navTabBlue cursor-pointer'>
                  Help and Support
                </li>
              </Link>
            </ul>
          </div>
          {/* Slooku Trademark */}
          <div className='text-center pb-4'>
            <Link to='/'>
              <div className='text-base text-gray-400 cursor-pointer'>
                {tradeMarkYear} Slooku. All rights reserved.
              </div>
            </Link>
          </div>
        </div>

        {/* footer-mobile-container  */}
        <div className='flex flex-col items-center md:hidden'>
          {/* Navigation Container */}
          <div className='flex items-center p-4'>
            <ul className='grid grid-cols-1 sm:grid-cols-2 text-center gap-4'>
              <Link to='/about-us'>
                <li className='text-base text-navTabBlue cursor-pointer'>
                  About Us
                </li>
              </Link>
              <Link to='/terms'>
                <li className='text-base text-navTabBlue cursor-pointer'>
                  Terms of Service
                </li>
              </Link>
              <Link to='/privacy-policy'>
                <li className='text-base text-navTabBlue cursor-pointer'>
                  Privacy Policy
                </li>
              </Link>
              <Link to='/help'>
                <li className='text-base text-navTabBlue cursor-pointer'>
                  Help and Support
                </li>
              </Link>
            </ul>
          </div>
          {/* Slooku Trademark */}
          <div className='text-center pb-4 px-4'>
            <Link to='/'>
              <div className='text-sm xs:text-base text-gray-400 cursor-pointer'>
                {tradeMarkYear} Slooku. All rights reserved.
              </div>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
