import React from 'react';

export const LoadingSpinner: React.FC = () => {
  return (
    <div className='flex justify-center items-center relative'>
      <div className='animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-gray-800'></div>
      <div className='absolute inset-0 flex justify-center items-center'>
        <p className='text-xl font-semibold'>Loading...</p>
      </div>
    </div>
  );
};
