import React, { useEffect, useState } from 'react';
import { FormViewLayout } from '../../../../../components';
import { StandardEstimateForm } from './Forms/StandardEstimateForm/StandardEstimateForm';
import { ContractorInfo } from '../../../../../types';
import { auth } from '../../../../../firebase/config';
import { GetContractorInformation } from '../../Settings/ContractorSettingsPageService';

export function TemplateFormView(): JSX.Element {
  const [contractorInfo, setContractorInfo] = useState<ContractorInfo | null>(
    null
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user !== null) {
        // fetch company information
        GetContractorInformation(user.uid)
          .then((contractorInfoObj) => {
            setContractorInfo(contractorInfoObj);
          })
          .catch(() => {
            // an error occurred while fetching company information
            // set company information to null
            // while still let user access the form
            // contractor information data will not be pre-filled
            setContractorInfo(null);
          })
          .finally(() => {
            setLoading(false); // set loading to false when data is loaded or after error
          });
      }
    });

    return unsubscribe;
  }, []);

  return (
    <FormViewLayout>
      {!loading && <StandardEstimateForm contractorInfo={contractorInfo} />}
    </FormViewLayout>
  );
}
