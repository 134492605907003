import React, { useEffect, useRef, useState } from 'react';
import { INavbarLinks } from '../Types/INavbarLinks';
import DropdownNavigation from '../DropdownNavigation/DropdownNavigation';
import HamburgerMenu from '../HambugerMenu/HamburgerMenu';

type HamburgerMenuWithDropdownNavigationProps = {
  navLinks: INavbarLinks[];
};

export default function HamburgerMenuWithDropdownNavigation({
  navLinks,
}: HamburgerMenuWithDropdownNavigationProps): JSX.Element {
  const isClickOutsideOfMobileMenu = useRef<HTMLHeadingElement>(null);
  const [dropDownNavMenuOpen, setDropDownNavMenuOpen] = useState(false);
  const [navDropDownButtonClick, setNavDropDownButtonClick] = useState(false);

  const handleBurgerMenuClick = () => {
    setDropDownNavMenuOpen((mobileNavbarOpen) => !mobileNavbarOpen);
    setNavDropDownButtonClick((navigationIconClick) => !navigationIconClick);
  };

  const handleClickOutside = (event: Event): void => {
    if (
      isClickOutsideOfMobileMenu.current &&
      !isClickOutsideOfMobileMenu.current.contains(event.target as Element)
    ) {
      setDropDownNavMenuOpen(false);
      setNavDropDownButtonClick(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isClickOutsideOfMobileMenu]);

  return (
    <div
      className='navigation-drop-container inline-block relative'
      ref={isClickOutsideOfMobileMenu}
    >
      {/* Hamburger Menu */}
      <HamburgerMenu
        dropDownNavMenuOpen={dropDownNavMenuOpen}
        onClick={handleBurgerMenuClick}
      />
      {/* Navigation Dropdown */}
      <DropdownNavigation
        showDropdownMenu={navDropDownButtonClick}
        onClick={handleBurgerMenuClick}
        navLinks={navLinks}
      />
    </div>
  );
}
