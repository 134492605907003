import React from 'react';
import { Link } from 'react-router-dom';

type CardRowDataProps = {
  dataText: string | number;
};

export default function CardRowDataLink({
  dataText,
}: CardRowDataProps): JSX.Element {
  return (
    <div className='flex-1 px-6 pb-4 pt-1 sm:pt-4 text-base text-[#509ef6] whitespace-nowrap overflow-hidden overflow-ellipsis'>
      <Link to={`${dataText}`}>{dataText}</Link>
    </div>
  );
}
