import React from 'react';

type Props = {
  children: React.ReactNode;
};

export default function TableRow({ children }: Props): JSX.Element {
  return (
    <tr className='border-b border-slookuGreyContainerPrimary'>{children}</tr>
  );
}
