import React from 'react';

type Props = {
  dataText: string | number;
  isLargeColumn?: boolean;
};

export default function TableColData({
  isLargeColumn,
  dataText,
}: Props): JSX.Element {
  return (
    <td
      className={
        'px-6 py-4 text-base text-gray-600 whitespace-nowrap overflow-hidden overflow-ellipsis ' +
        (isLargeColumn ? 'max-w-[360px]' : 'max-w-[180px]')
      }
    >
      {dataText}
    </td>
  );
}
