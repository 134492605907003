import React from 'react';
import HamburgerMenuWithDropdownNavigation from '../HamburgerMenuWithDropdownNavigation/HamburgerMenuWithDropdownNavigation';
import SlookuLogoTitle from '../SlookuLogoTitle/SlookuLogoTitle';
import NavigationLinks from './NavigationLinks/NavigationLinks';
import DropDownNonAuthUserLinks from '../../Navbar/Links/DropDownLinks.json';
import NavbarNonAuthUserLinks from '../../Navbar/Links/NavbarLinks.json';

/* Simple header - only logo and dropdown nav NO SEARCH */
export default function NavbarContent(): JSX.Element {
  return (
    <>
      {/* Logo-Title and Desktop Navigation link */}
      <div className='flex flex-row gap-8'>
        {/* Logo-Title */}
        <SlookuLogoTitle />
        {/* Navigation Container */}
        <div className='hidden md:flex'>
          <NavigationLinks navLinks={NavbarNonAuthUserLinks} />
        </div>
      </div>
      {/* Hamburger Menu with Dropdown Navigation */}
      <HamburgerMenuWithDropdownNavigation
        navLinks={DropDownNonAuthUserLinks}
      />
    </>
  );
}
