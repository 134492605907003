import { auth, db } from '../../../../../firebase/config';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { StandardEstimateDataForm } from '../../../../../types';
import {
  contractorDocumentsCollection,
  contractsSubCollection,
} from '../../../../../shared/constants';

export function CalculateSubTotalAmount(
  costs: StandardEstimateDataForm['costs']
) {
  // in case of empty array it will return 0
  return Number(
    costs
      .reduce(
        (acc, current) => acc + (current.price || 0) * (current.quantity || 0),
        0
      )
      .toFixed(2)
  );
}

export function CalculateTaxAmount(taxVal: number, subTotalAmount: number) {
  // if NaN, undefined or negative then default to 0 otherwise use value
  return taxVal && taxVal > 0
    ? Number((subTotalAmount * (taxVal / 100)).toFixed(2))
    : 0;
}

export function CalculateTotalAmount(taxVal: number, subTotalAmount: number) {
  // Calculate the subtotal by adding the total cost and the tax amount
  const subTotal = taxVal + subTotalAmount;

  // Format the subtotal as a currency string with two decimal places
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  return formatter.format(subTotal);
}

export const createContract = async (
  contractData: StandardEstimateDataForm,
  contractType: string
) => {
  let documentCreated = false;

  await addDoc(
    collection(
      db,
      `${contractorDocumentsCollection}/${auth.currentUser?.uid}/${contractsSubCollection}`
    ),
    {
      ...contractData,
      type: contractType,
      createdAt: Timestamp.now(),
    }
  )
    .then(() => {
      // End: createContract: contract in firestore has been created successfully.
      documentCreated = true;
    })
    .catch(() => {
      documentCreated = false;
    });

  return documentCreated;
};
