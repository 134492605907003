import React, {
  createContext,
  Dispatch,
  useReducer,
  useContext,
  useEffect,
} from 'react';
import { AuthUser, ContractorInfo, CustomerInfo, Role } from '../types';
import { auth } from '../firebase/config';
import { snapshotToDoc, contractorsRef } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';

interface Props {
  children: React.ReactNode;
}

type AuthState = {
  authUser: AuthUser | null;
  userInfo: ContractorInfo | CustomerInfo | null;
  userRole: Role | null;
};
const AuthStateContext = createContext<AuthState | undefined>(undefined);

type FETCH_AUTH_USER = { type: 'FETCH_AUTH_USER'; payload: AuthUser | null };
type FETCH_USER_INFO = {
  type: 'FETCH_USER_INFO';
  payload: ContractorInfo | CustomerInfo | null;
};
type SET_USER_ROLE = { type: 'SET_USER_ROLE'; payload: Role | null };

type AuthActions = FETCH_AUTH_USER | FETCH_USER_INFO | SET_USER_ROLE;
type AuthDispatch = Dispatch<AuthActions>;
const AuthDispatchContext = createContext<AuthDispatch | undefined>(undefined);

// Action creators
export const fetchAuthUser = (user: AuthUser | null): FETCH_AUTH_USER => ({
  type: 'FETCH_AUTH_USER',
  payload: user,
});

export const fetchUserInfo = (
  userInfo: ContractorInfo | CustomerInfo | null
): FETCH_USER_INFO => ({
  type: 'FETCH_USER_INFO',
  payload: userInfo,
});

export const setUserRole = (role: Role | null): SET_USER_ROLE => ({
  type: 'SET_USER_ROLE',
  payload: role,
});

// Auth Reducer function initial state
const initialState: AuthState = {
  authUser: null,
  userInfo: null,
  userRole: null,
};

// Auth Reducer function
const authReducer = (state: AuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case 'FETCH_AUTH_USER':
      return {
        ...state, // copy all properties from AuthState
        authUser: action.payload, // case of FETCH_AUTH_USER: update only this property from AuthState
      };
    case 'FETCH_USER_INFO':
      return {
        ...state,
        userInfo: action.payload,
      };
    case 'SET_USER_ROLE':
      return {
        ...state,
        userRole: action.payload,
      };
    default:
      return state;
  }
};

const AuthContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [authState, authDispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    // Listen for changes in the user's authentication state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // get user role from firebase auth
        user
          .getIdTokenResult()
          .then((result) => {
            const role = result.claims.role as Role;

            // set user role and auth user in state
            authDispatch(setUserRole(role));
            authDispatch(fetchAuthUser(user));
          })
          .catch(() => {
            // error occurred getting user role - set to un-auth user
            authDispatch(setUserRole('DEFAULT'));
            authDispatch(fetchAuthUser(null));
          });
      } else {
        // user is not authenticated - set to un-auth user
        authDispatch(setUserRole('DEFAULT'));
        authDispatch(fetchAuthUser(null));
      }
      // Cleanup the subscription
      return () => unsubscribe();
    });
  }, []);

  // Listen to user document changed in firestore
  useEffect(() => {
    // user not logged in
    if (!authState.authUser) {
      return authDispatch(fetchUserInfo(null));
    }

    // get document by current user id
    const unsubscribe = onSnapshot(
      doc(contractorsRef, authState.authUser.uid),
      (snapshot) => {
        // document does not exist
        if (!snapshot.exists) {
          return authDispatch(fetchUserInfo(null));
        }

        // document exist - store document properties in state to have available across entire app
        const userInfo = snapshotToDoc<ContractorInfo>(snapshot);
        authDispatch(fetchUserInfo(userInfo));
      },
      () => {
        authDispatch(fetchUserInfo(null));
      }
    );

    // Cleanup the subscription
    return () => unsubscribe();
  }, [authState.authUser]);

  return (
    <AuthStateContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={authDispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export default AuthContextProvider;

export const useAuthContext = () => {
  const authState = useContext(AuthStateContext);
  const authDispatch = useContext(AuthDispatchContext);

  if (authState === undefined || authDispatch === undefined)
    throw new Error('useAuthContext must be used within AuthContextProvider.');

  return { authState, authDispatch };
};
