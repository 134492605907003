import React, { InputHTMLAttributes, forwardRef, Ref } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  type?: string;
}

export default forwardRef<HTMLInputElement, Props>(
  function RoundedInputWithValidation(
    { label, error, type = 'text', ...props }: Props,
    ref: Ref<HTMLInputElement>
  ) {
    return (
      <div>
        <label
          htmlFor={label}
          className='block text-grey-darker font-bold text-sm mb-2'
        >
          {label}
        </label>
        <input
          className={
            'shadow appearance-none border rounded-3xl w-full py-2 pl-4 pr-3 text-gray-700 leading-tight focus:outline-none focus:ring-1 focus:ring-blue-600 ' +
            (error && ' ring-1 ring-red-600')
          }
          type={type}
          {...props}
          ref={ref}
        />
        {error && (
          <p className='max-w-[200px] sm:max-w-full text-[#ff0000] text-xs italic mt-2 mx-4'>
            {error}
          </p>
        )}
      </div>
    );
  }
);
