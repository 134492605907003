import React, { useState } from 'react';
import NavbarContractor from '../../../../components/Navbar/Contractor/NavbarContractor';
import Footer from '../../../../components/Footer/Footer';
import { TemplateFormView } from './FormView/TemplateFormView';
import { TemplatesGrid } from './Grid/TemplatesGrid';

export function TemplatesPage(): JSX.Element {
  const [formView, setFormView] = useState(false);

  return (
    <div
      id='contractor-templates-page'
      className='w-full h-screen flex flex-col'
    >
      <NavbarContractor />
      {!formView ? (
        <TemplatesGrid setFormView={() => setFormView(!formView)} />
      ) : (
        <TemplateFormView />
      )}
      <Footer />
    </div>
  );
}
