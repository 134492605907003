import React from 'react';
import NavbarContainer from '../shared/NavbarContainer/NavbarContainer';
import SlookuLogoTitle from '../shared/SlookuLogoTitle/SlookuLogoTitle';

// component only contains the logo to get back home. no routing links
export function HomeNavbar(): JSX.Element {
  return (
    <NavbarContainer>
      <SlookuLogoTitle />
    </NavbarContainer>
  );
}
