import React from 'react';
import HamburgerMenuWithDropdownNavigation from '../../HamburgerMenuWithDropdownNavigation/HamburgerMenuWithDropdownNavigation';
import NavigationLinks from '../NavigationLinks/NavigationLinks';
import SlookuLogoTitle from '../../SlookuLogoTitle/SlookuLogoTitle';
import NavbarContractorLinks from '../../../../Navbar/Contractor/Links/NavbarContractorLinks.json';
import DropDownContractorLinks from '../../../../Navbar/Contractor/Links/DropDownContractorLinks.json';

export default function NavbarContentContractor(): JSX.Element {
  return (
    <>
      {/* Logo-Title and Desktop Navigation link */}
      <div className='flex flex-row gap-8'>
        {/* Logo-Title */}
        <SlookuLogoTitle />
        {/* Navigation Container */}
        <div className='hidden md:flex'>
          <NavigationLinks navLinks={NavbarContractorLinks} />
        </div>
      </div>
      {/* Hamburger Menu with Dropdown Navigation */}
      <HamburgerMenuWithDropdownNavigation navLinks={DropDownContractorLinks} />
    </>
  );
}
