import React from 'react';

type CardRowProps = {
  children: React.ReactNode;
  isLastRow?: boolean;
};

export default function CardRow({
  children,
  isLastRow,
}: CardRowProps): JSX.Element {
  return (
    <div
      className={
        isLastRow
          ? 'flex flex-col sm:flex-row w-full'
          : 'flex flex-col sm:flex-row w-full border-b border-slookuGreyContainerPrimary'
      }
    >
      {children}
    </div>
  );
}
