import React from 'react';
import { AccountReset } from './Components/AccountReset/AccountReset';
import Footer from '../../../../components/Footer/Footer';
import NavbarContractor from '../../../../components/Navbar/Contractor/NavbarContractor';
import { FormContainer } from '../../../../components';
import { CompanyInformation } from './Components/CompanyInformation/CompanyInformation';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function ContractorSettingsPage(): JSX.Element {
  return (
    <div
      id='contractor-settings-page'
      className='w-full h-screen flex flex-col'
    >
      <NavbarContractor />
      <ToastContainer position={'top-center'} />
      <main className='flex justify-center pt-16 md:pt-20 pb-8 px-4 md:px-8'>
        <div className='flex flex-col w-[90%] max-w-[800px] gap-8'>
          {/* Page Title */}
          <div className='flex flex-col items-start w-full'>
            <h1 className='text-2xl xs:text-3xl'>Account Settings</h1>
          </div>
          {/* CONTACT INFORMATION */}
          <FormContainer>
            <CompanyInformation />
          </FormContainer>
          {/* Password */}
          <FormContainer>
            <AccountReset />
          </FormContainer>
        </div>
      </main>
      <Footer />
    </div>
  );
}
