import React from 'react';

export function PricingPlansHeader() {
  return (
    <div className='flex flex-col gap-4 px-4'>
      <h1 className='font-base text-4xl lg:text-5xl text-center'>
        Contractors choice for easy contract management
      </h1>
      <span className='font-light text-lg lg:text-xl text-center text-gray-500'>
        It&apos;s the perfect tool for businesses looking for a single, unified
        platform to manage their invoicing, contracts, and more.
      </span>
    </div>
  );
}
