import React, { useState } from 'react';

type InputWithBottomBorderPassProps = {
  inputTitle: string;
  placeHolder: string;
  inputLabel: string;
};

function InputWithBottomBorderPass({
  inputTitle,
  placeHolder,
  inputLabel,
}: InputWithBottomBorderPassProps): JSX.Element {
  const [isPassVisible, setIsPassVisible] = useState(false);

  const onViewPassPress = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsPassVisible(!isPassVisible);
  };

  return (
    <div className='flex flex-col gap-2'>
      {/* Header */}
      <div className='flex'>
        <h1 className='font-semibold ml-2'>{inputTitle}</h1>
      </div>
      {/* Input */}
      <div className='flex items-center relative'>
        <input
          className='w-full
            pl-2
            pr-9
            overflow-hidden            
            py-2
            text-base
            border-b-2 border-[slookuLightGreyBackground]
            focus:outline-none focus:border-blue-400'
          type={isPassVisible ? 'text' : 'password'}
          placeholder={placeHolder}
          aria-label={inputLabel}
        />
        {/* Show and hide pass toggle button with eye icon */}
        <div className='absolute inset-y-0 right-0 flex items-center'>
          <button
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              onViewPassPress(e)
            }
          >
            {/* prevent default since page refreshes */}
            <img
              src={
                isPassVisible
                  ? '/assets/shared/icons/eye_open.svg'
                  : '/assets/shared/icons/eye_closed.svg'
              }
              className={'h-4 w-auto'}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default InputWithBottomBorderPass;
