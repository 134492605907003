import React from 'react';
import NavbarContainer from '../shared/NavbarContainer/NavbarContainer';
import NavbarContentSearch from '../shared/NavbarContent/Search/NavbarContentSearch';
import NavbarCustomerLinks from './Links/NavbarCustomerLinks.json';

export default function NavbarCustomer(): JSX.Element {
  return (
    <NavbarContainer>
      {/* Header with search bar shown in all pages */}
      <NavbarContentSearch navLinks={NavbarCustomerLinks} />
    </NavbarContainer>
  );
}
