import React from 'react';
import InputWithBottomBorder from '../../../../../components/InputWithBottomBorder/InputWithBottomBorder';
import SaveButton from '../Shared/SaveButton/SaveButton';

function PersonalInformation(): JSX.Element {
  const PERSONAL_INFORMATION = 'Personal Information';

  return (
    <form className='flex flex-col gap-4'>
      {/* Header */}
      <div className='flex'>
        {/* Title */}
        <div className='text-2xl font-bold'>
          <h1>{PERSONAL_INFORMATION}</h1>
        </div>
      </div>
      {/* Note to contractors */}
      <div className='flex flex-row gap-1'>
        <div className='flex flex-col shrink-0 mt-[2px]'>
          <img
            className='w-[20px] h-[20px]'
            src='/assets/shared/icons/ExclamationCircle.svg'
            alt='exclamation icon'
          />
        </div>
        <div className='flex flex-col'>
          <span className='text-[#676d73] opacity-80'>
            Note: This phone number will be used to recover your account. Use
            your personal cell phone number here. This number will not be
            public.
          </span>
        </div>
      </div>
      {/* Input Grid */}
      <div className='input-grid grid grid-cols-1 sm2:grid-cols-2 gap-4'>
        <InputWithBottomBorder
          inputTitle={'First Name'}
          iconSource={'/assets/shared/icons/profile_icon.svg'}
          placeholder={'John'}
          type='text'
          aria-label={'Input for First Name'}
        />
        <InputWithBottomBorder
          inputTitle={'Last Name'}
          placeholder={'Doe'}
          type='text'
          aria-label={'Input for Last Name'}
        />
        <InputWithBottomBorder
          inputTitle={'Phone'}
          iconSource={'/assets/shared/icons/Phone.svg'}
          placeholder={'214-555-5555'}
          type='text'
          aria-label={'Input for Phone Number'}
        />
      </div>
      {/* Save Changes Button */}
      <div className='flex w-full justify-center mt-2'>
        <SaveButton />
      </div>
    </form>
  );
}

export default PersonalInformation;
