import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ContractorSettingsPage } from '../../pages/App/Contractor';
import { ErrorPage } from '../../pages/Shared';
import { TemplatesPage } from '../../pages/App/Contractor';
import DocumentsPage from '../../pages/App/Contractor/Documents/DocumentsPage';
import DocumentByIdPage from '../../pages/App/Contractor/DocumentById/DocumentByIdPage';
import { PrivateRoute } from '../PrivateRoute';

export default function ContractorRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<PrivateRoute userRoleType='CONTRACTOR' />}>
        <Route path='/settings' element={<ContractorSettingsPage />} />
        <Route path='/templates' element={<TemplatesPage />} />
        <Route path='/documents' element={<DocumentsPage />} />
        <Route path='/documents/:id' element={<DocumentByIdPage />} />
        <Route path='*' element={<ErrorPage />} />
      </Route>
    </Routes>
  );
}
