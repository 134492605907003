import React, { ButtonHTMLAttributes, forwardRef, Ref } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  src?: string | undefined;
  label: string;
}

export default forwardRef(function WhiteRoundedButtonWithLabel(
  { src, label, ...props }: Props,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <button className='flex flex-col items-center' ref={ref} {...props}>
      {/* circular black background with img centered */}
      <div className='w-[60px] h-[60px] rounded-full border-[1px] border-slate-400 flex flex-col items-center justify-center hover:bg-slate-200'>
        <img alt='icon' src={src} className='h-[35px] w-[35px]' />
      </div>
      {/* button label */}
      <span className='font-light'>{label}</span>
    </button>
  );
});
