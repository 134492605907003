import React from 'react';

type Props = {
  headerText: string;
};

export default function TableColHeader({ headerText }: Props): JSX.Element {
  return (
    <th scope='col' className='px-6 py-4 text-base font-normal'>
      {headerText}
    </th>
  );
}
