import React from 'react';
import { Footer, HomeNavbar } from '../../../components';

export function AboutUsPage(): JSX.Element {
  const AboutUsHeader = () => {
    return (
      <div className='flex flex-col gap-8 px-2 md2:px-0'>
        <h1 className='text-6xl xs:text-7xl font-light'>About Slooku</h1>
        <div className='max-w-[800px]'>
          <p className='text-lg font-light'>
            Based out of Texas, Slooku is a leading contract management service
            dedicated to ensuring simplicity and fairness for all contractors,
            homeowners, and business owners.
          </p>
        </div>
      </div>
    );
  };

  const MissionAndVisionSection = () => {
    return (
      <div className='flex flex-col md2:flex-row gap-8 md2:gap-12 px-2 md2:px-0'>
        <div className='w-full md2:w-1/2 flex flex-col gap-4'>
          <h2 className='text-4xl font-light'>Our Mission</h2>
          <p className='text-lg font-light'>
            Our mission is to provide a platform that connects homeowners with
            contractors to make home improvement projects easier.
          </p>
        </div>
        <div className='w-full md2:w-1/2 flex flex-col gap-4'>
          <h2 className='text-4xl font-light'>Our Vision</h2>
          <p className='text-lg font-light'>
            Our vision is to be the go-to platform nationwide for homeowners and
            contractors to connect and collaborate on home improvement projects.
          </p>
        </div>
      </div>
    );
  };
  const QASection = () => {
    return (
      <div className='flex flex-col lg2:flex-row gap-12'>
        {/* QAs - img */}
        <div className='w-full h-full flex justify-center'>
          <img
            alt='icon'
            src={'/assets/pages/Shared/AboutUs/about-us-2-no-bg.png'}
            className='w-[250px] h-[250px] xs:h-[350px] xs:w-[350px] sm:h-[450px] sm:w-[450px] sm2:h-[550px] sm2:w-[550px] rounded-3xl'
          />
        </div>
        {/* QAs - text */}
        <div className='w-full h-full flex flex-col justify-center gap-8'>
          <div className='flex flex-col gap-2 px-2 lg2:px-8'>
            <h2 className='text-2xl font-normal'>What do we do?</h2>
            <p className='text-lg font-light'>
              We provide a simple, transparent, and fair platform for all
              parties involved in the contract management process. Our platform
              is designed to simplify the contract management process and ensure
              that all parties are treated fairly.
            </p>
          </div>
          <div className='flex flex-col gap-2 px-2 lg2:px-8'>
            <h2 className='text-2xl font-normal'>How do we do it?</h2>
            <p className='text-lg font-light'>
              We provide the tools and resources necessary to simplify the lives
              of everyone involved in any major home improvement project. Our
              platform is designed to provide a intuitive and easy to use
              experience for all of our clients.
            </p>
          </div>
          <div className='flex flex-col gap-2 px-2 lg2:px-8'>
            <h2 className='text-2xl font-normal'>Why do we do it?</h2>
            <p className='text-lg font-light'>
              We care about you. We understand that home improvement projects
              can go wrong and we want to make sure that you are protected. Our
              platform is designed to ensure that you are treated fairly and
              that any home improvement project is a success.
            </p>
          </div>
        </div>
      </div>
    );
  };

  function ContactUs() {
    return (
      <div className='w-full flex flex-col justify-center items-center gap-12'>
        <h1 className='text-4xl sm2:text-5xl font-normal'>Contact Us</h1>
        <div className='w-full flex flex-col md2:flex-row justify-between items-center gap-16 md2:gap-32 xl:gap-44 p-8 md:p-16 rounded-3xl bg-gradient-to-r from-blue-500 to-[#1E47F6]'>
          {/* sub header text */}
          <div className='flex flex-col text-center'>
            <h1 className='text-white text-4xl sm:text-5xl font-extralight'>
              We are happy to assist you!
            </h1>
          </div>
          {/* contact us information */}
          <div className='flex flex-col justify-center gap-4'>
            <span className='text-xl sm:text-3xl md:text-3xl font-semibold text-white'>
              contact@slooku.com
            </span>
            <span className='text-xl sm:text-3xl md:text-3xl text-white font-extralight'>
              Feel free to send us an email.
            </span>
            <div className='flex flex-col'>
              <span className='text-xl sm:text-3xl md:text-3xl text-white font-extralight'>
                Monday - Friday
              </span>
              <span className='text-xl sm:text-3xl md:text-3xl text-white font-extralight'>
                8:00 AM - 6:00 PM CST.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='about-us-page' className='w-full h-screen flex flex-col'>
      <HomeNavbar />
      <main className='flex flex-col px-4 md:px-8 pt-16'>
        <div className='w-full h-full flex flex-col gap-12 md2:gap-20'>
          {/* about us container */}
          <AboutUsHeader />
          {/* mission and vision container*/}
          <MissionAndVisionSection />
          {/* about us - qa container */}
          <QASection />
          {/* contact us - container */}
          <ContactUs />
        </div>
      </main>
      <Footer />
    </div>
  );
}
