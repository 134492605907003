import React from 'react';

type CardRowDataProps = {
  dataText: string | number;
};

export default function CardRowData({
  dataText,
}: CardRowDataProps): JSX.Element {
  return (
    <div className='flex-1 px-6 pb-4 pt-1 sm:pt-4 text-base text-gray-600 whitespace-nowrap overflow-hidden overflow-ellipsis'>
      {dataText}
    </div>
  );
}
