import React from 'react';
import { useForm } from 'react-hook-form';
import { company, ContractorInfo } from '../../../../../../types';
import InputWithBottomBorder from '../../../../../../components/InputWithBottomBorder/InputWithBottomBorder';
import { SaveButton } from '../Shared/SaveButton/SaveButton';
import {
  FormErrorElementContainer,
  StatusMessageWarning,
} from '../../../../../../components';
import { useAuthContext } from '../../../../../../state/auth-context';
import { toast } from 'react-toastify';
import { UpdateContractorCompanyInformation } from '../../ContractorSettingsPageService';

const requiredPrompt = 'Required.';

type CompanyInformationFormProps = {
  contractorInfo: ContractorInfo;
};

export function CompanyInformationForm({
  contractorInfo,
}: CompanyInformationFormProps): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<company>({
    mode: 'onChange',
    defaultValues: {
      name: contractorInfo.company?.name || '',
      contact: {
        email: contractorInfo.company?.contact.email || '',
        phoneNumber: contractorInfo.company?.contact.phoneNumber || '',
      },
      address: {
        street: contractorInfo.company?.address.street || '',
        city: contractorInfo.company?.address.city || '',
        state: contractorInfo.company?.address.state || '',
        zipcode: contractorInfo.company?.address.zipcode || '',
      },
    },
  });

  const {
    authState: { userInfo },
  } = useAuthContext();

  const onSubmitContactInformation = async (data: company) => {
    const savingContactInfoToast = toast('Saving company information...', {
      type: 'info',
    });
    const contractorId = userInfo?.id as string;

    const success = await UpdateContractorCompanyInformation(
      contractorId,
      data
    );

    if (success) {
      toast.update(savingContactInfoToast, {
        render: 'Company information updated successfully.',
        type: 'success',
        autoClose: 5000,
      });
      reset({}, { keepValues: true });
    } else {
      toast.update(savingContactInfoToast, {
        render:
          'An error occurred while updating company information. Please try again later.',
        type: 'error',
        autoClose: 5000,
      });
    }
  };

  return (
    <form
      className='flex flex-col gap-4'
      onSubmit={handleSubmit(onSubmitContactInformation)}
    >
      {/* company email */}
      <div className='grid grid-cols-1'>
        <InputWithBottomBorder
          {...register('contact.email', {
            required: requiredPrompt,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Invalid email address',
            },
          })}
          inputTitle={'Email'}
          placeholder={'example@email.com'}
          type='text'
          aria-label={'Input for email'}
        />
        {errors.contact?.email?.message && (
          <FormErrorElementContainer
            inputErrorMsg={errors.contact.email?.message}
          />
        )}
      </div>
      {/* company name */}
      <div className='grid grid-cols-1'>
        <InputWithBottomBorder
          {...register('name', {
            required: requiredPrompt,
          })}
          inputTitle={'Company Name'}
          iconSource={'/assets/shared/icons/profile_icon.svg'}
          placeholder={'Company Name'}
          type='text'
          aria-label={'Input for company name'}
        />
        {errors.name?.message && (
          <FormErrorElementContainer inputErrorMsg={errors.name?.message} />
        )}
      </div>
      {/* company phone */}
      <div className='grid grid-cols-1'>
        <InputWithBottomBorder
          {...register('contact.phoneNumber', {
            required: requiredPrompt,
            pattern: {
              value: /^\d{3}-\d{3}-\d{4}$/, // 000-000-000 format
              message: 'Required format: ###-###-####' /* ERROR MSG */,
            },
          })}
          inputTitle={'Phone'}
          iconSource={'/assets/shared/icons/Phone.svg'}
          placeholder={'214-555-5555'}
          type='text'
          aria-label={'Input for Phone Number'}
        />
        {errors.contact?.phoneNumber?.message && (
          <FormErrorElementContainer
            inputErrorMsg={errors.contact.phoneNumber?.message}
          />
        )}
      </div>
      {/* company address */}
      <div className='grid grid-cols-1 sm2:grid-cols-2 gap-4'>
        <div>
          <InputWithBottomBorder
            {...register('address.street', {
              required: requiredPrompt,
            })}
            inputTitle={'Street'}
            iconSource={'/assets/shared/icons/LocationMarker.svg'}
            placeholder={'#123 Street Name'}
            type='text'
            aria-label={'Input for Street'}
          />
          {errors.address?.street?.message && (
            <FormErrorElementContainer
              inputErrorMsg={errors.address?.street?.message}
            />
          )}
        </div>
        <div>
          <InputWithBottomBorder
            {...register('address.city', {
              required: requiredPrompt,
            })}
            inputTitle={'City'}
            placeholder={'Dallas'}
            type='text'
            aria-label={'Input for City'}
          />
          {errors.address?.city?.message && (
            <FormErrorElementContainer
              inputErrorMsg={errors.address.city?.message}
            />
          )}
        </div>
        <div>
          <InputWithBottomBorder
            {...register('address.state', {
              required: requiredPrompt,
            })}
            inputTitle={'State'}
            placeholder={'Texas'}
            type='text'
            aria-label={'Input for State'}
          />
          {errors.address?.state?.message && (
            <FormErrorElementContainer
              inputErrorMsg={errors.address.state?.message}
            />
          )}
        </div>
        <div>
          <InputWithBottomBorder
            {...register('address.zipcode', {
              required: requiredPrompt,
            })}
            inputTitle={'Zipcode'}
            placeholder={'75000'}
            type='text'
            aria-label={'Input for Zipcode'}
          />
          {errors.address?.zipcode?.message && (
            <FormErrorElementContainer
              inputErrorMsg={errors.address.zipcode?.message}
            />
          )}
        </div>
      </div>
      {/* Submit Button */}
      <div className='flex flex-col items-center pt-4'>
        {!isDirty && (
          <StatusMessageWarning message='Edit from to save changes.' />
        )}
        {isDirty && <SaveButton />}
      </div>
    </form>
  );
}
