import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthContext } from '../state/auth-context';
import { Role } from '../types';
import { LoadingSpinner } from './shared/LoadingSpinner';

interface PrivateRoutesProps {
  userRoleType: Role;
}

export const PrivateRoute = ({
  userRoleType,
}: PrivateRoutesProps): React.ReactElement | null => {
  const location = useLocation();
  const {
    authState: { authUser, userRole },
  } = useAuthContext();

  /* complete page refresh - auth context values will be set to initialState */
  if (userRole === null) {
    return <LoadingSpinner />;
  }

  /* non auth user - will be null and DEFAULT  */
  if (!authUser && userRole !== userRoleType) {
    return <Navigate to='/log-in' replace state={{ from: location }} />;
  }

  /* load nested routes when user is authenticated and userRole is correct type */
  return <Outlet />;
};
