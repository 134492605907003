import React, { useEffect, useRef, useState } from 'react';
import './ContractsGridActionsMenu.css';
import { Link } from 'react-router-dom';

type Props = {
  contractId: string;
  onEmailContractClick: (contractId: string) => Promise<void>;
};

type KebabMenuProps = {
  onClick: () => void;
};

function KebabMenu({ onClick }: KebabMenuProps): JSX.Element {
  return (
    <button
      className='px-1 py-3 rounded-full border border-gray-300'
      onClick={onClick}
    >
      <img
        alt='icon'
        src='/assets/shared/icons/kebab-icon.png'
        className='h-1.5'
      />
    </button>
  );
}

type ActionsDropDownMenuProps = {
  contractId: string;
  showDropdownMenu: boolean;
  handleEmailContractClick: () => void;
};

function ActionsDropDownMenu({
  contractId,
  showDropdownMenu,
  handleEmailContractClick,
}: ActionsDropDownMenuProps): JSX.Element {
  return (
    <ul
      className={
        'absolute z-20 right-8 sm:right-12 lg2:right-20 xl:right-24 w-[300px] max-w-[80vw] mt-2 px-2 py-4 bg-white border border-slate-200 md:border-none rounded-md documents-grid-actions-menu-shadow ' +
        (showDropdownMenu ? 'block ' : ' hidden')
      }
    >
      {/* send contract as pdf through email */}
      <li className='flex'>
        <button
          className='w-full flex flex-row items-center my-1 mx-1 px-2 py-1 hover:bg-slate-200 hover:rounded-md'
          onClick={handleEmailContractClick}
        >
          <img
            alt='icon'
            src='/assets/shared/icons/envelope-contractor-actions-dropdown-icon.png'
            className='h-[15px] w-[25px]'
          />
          <span className='ml-2 text-base'>Email Contract</span>
        </button>
      </li>
      {/* navigate to contractor editor */}
      <Link to={`./${contractId}`} className='flex'>
        <li className='w-full flex flex-row items-center my-1 mx-1 px-2 py-1 hover:bg-slate-200 hover:rounded-md'>
          <img
            alt='icon'
            src='/assets/shared/icons/pencil-icon.png'
            className='h-[15px] w-[25px]'
          />
          <span className='ml-2 text-base'>Edit</span>
        </li>
      </Link>
    </ul>
  );
}

export default function ContractsGridActionsMenu({
  contractId,
  onEmailContractClick,
}: Props): JSX.Element {
  const isClickOutsideKebabMenu = useRef<HTMLHeadingElement>(null);
  const [navDropDownButtonClick, setNavDropDownButtonClick] = useState(false);

  const handleKebabMenuClick = () => {
    setNavDropDownButtonClick((navigationIconClick) => !navigationIconClick);
  };

  const handleEmailContractClick = async () => {
    setNavDropDownButtonClick(false);
    await onEmailContractClick(contractId);
  };

  const handleClickOutside = (event: Event): void => {
    if (
      isClickOutsideKebabMenu.current &&
      !isClickOutsideKebabMenu.current.contains(event.target as Element)
    ) {
      setNavDropDownButtonClick(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isClickOutsideKebabMenu]);

  return (
    <div className='mb-4 mt-3 lg:ml-[35px]' ref={isClickOutsideKebabMenu}>
      {/* Kebab Menu */}
      <KebabMenu onClick={handleKebabMenuClick} />
      <ActionsDropDownMenu
        contractId={contractId}
        showDropdownMenu={navDropDownButtonClick}
        handleEmailContractClick={handleEmailContractClick}
      />
    </div>
  );
}
