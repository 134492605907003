import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { INavbarLinks } from '../../Types/INavbarLinks';

type NavigationLinksProps = {
  navLinks: INavbarLinks[];
};

export default function NavigationLinks({
  navLinks,
}: NavigationLinksProps): JSX.Element {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const currentPath = window.location.pathname;
    // invalid index will be -1 and will not apply any underline
    const currentTab = navLinks.findIndex((link) => link.path === currentPath);
    setSelectedTab(currentTab);
  }, []);

  return (
    <ul className='flex items-center gap-8 pt-3'>
      {navLinks &&
        navLinks.length != 0 &&
        navLinks.map((link, index) => (
          <Link key={index} to={link.path}>
            <li
              className={
                'text-base text-navTabDarkGrey cursor-pointer hover:underline ' +
                (index == selectedTab && ' underline')
              }
            >
              {link.name}
            </li>
          </Link>
        ))}
    </ul>
  );
}
