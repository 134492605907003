import React from 'react';

type CardRowHeaderProps = {
  headerText: string;
};

export default function CardRowHeader({
  headerText,
}: CardRowHeaderProps): JSX.Element {
  return (
    <div className='flex-1 px-6 pt-4 pb-1 sm:pb-4 text-base font-normal'>
      {headerText}
    </div>
  );
}
