import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAsyncCall } from './useAsyncCall';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { auth } from '../firebase/config';
import { SignUpDataForm, SignInDataForm } from '../types';

export const useAuthenticate = () => {
  const { loading, setLoading, error, setError, successMsg, setSuccessMsg } =
    useAsyncCall();

  const signUp = async (data: SignUpDataForm) => {
    const { email, password } = data;

    try {
      setLoading(true);

      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // return null if an error occurred creating a user
      if (!response) {
        setErrorState('Sorry, something went wrong while creating a user.');
        return;
      }

      // set user display name same as the email they signed up with
      await updateProfile(response.user, {
        displayName: email,
      });

      // create new user in firestore user collection
      const functions = getFunctions();
      const onContractorSignup = httpsCallable(functions, 'contractorSignUp');
      // return will be an object that contains a success msg
      const data = await onContractorSignup();

      setSuccessState('created');
      return data;
    } catch (err) {
      const { message } = err as { message: string };
      if (message.includes('auth/email-already-in-use')) {
        setErrorState(
          'There is an account associated with this email. Please use a different email.'
        );
      } else {
        setErrorState(
          'Sorry, an error occurred while creating a user. Try again later.'
        );
      }
    }
  };

  const signOut = async () => {
    try {
      setLoading(true);
      await auth.signOut().then(() => {
        setLoading(false);
      });
    } catch (err) {
      const { message } = err as { message: string };

      setError(message);
      setLoading(false);
    }
  };

  const signIn = async (data: SignInDataForm) => {
    const { email, password } = data;

    try {
      setLoading(true);

      const response = await signInWithEmailAndPassword(auth, email, password);

      if (!response) {
        setErrorState(
          'Sorry, something went wrong trying to sign in. Try again later.'
        );
        return;
      }

      setSuccessState('success');
      return response;
    } catch (err) {
      const { message } = err as { message: string };

      if (message.includes('auth/user-not-found')) {
        setErrorState(
          'There is no account associated with this email. Please try again.'
        );
      } else if (message.includes('auth/wrong-password')) {
        setErrorState(
          'Incorrect Password. Please try again. After 3 tries, you will be locked out.'
        );
      } else if (message.includes('auth/too-many-requests')) {
        setErrorState(
          'This account has been temporarily disabled due to many failed login attempts. Reset your password or try again later.'
        );
      } else {
        setErrorState(
          'Sorry, an error occurred trying to sign in. Try again later.'
        );
      }
    }
  };

  const resetPassword = async (data: Omit<SignInDataForm, 'password'>) => {
    try {
      setLoading(true);

      await sendPasswordResetEmail(auth, data.email);

      setSuccessState('Please check your email to reset your password.');
      return;
    } catch (err) {
      const { message } = err as { message: string };
      if (message.includes('auth/user-not-found')) {
        setErrorState(
          'There is no account associated with this email. Please use a valid email.'
        );
      } else {
        setErrorState(
          'Sorry, an error occurred trying to sign in. Try again later.'
        );
      }
    }
  };

  const setErrorState = (errorMsg: string) => {
    setSuccessMsg('');
    setError(errorMsg);
    setLoading(false);
  };

  const setSuccessState = (successMsg: string) => {
    setSuccessMsg(successMsg);
    setError('');
    setLoading(false);
  };

  return {
    signUp,
    signIn,
    signOut,
    resetPassword,
    loading,
    error,
    successMsg,
  };
};
