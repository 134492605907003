import React, { useEffect, useState } from 'react';
import { Footer, Navbar, NavbarContractor } from '../../../components';
import { useAuthContext } from '../../../state/auth-context';
import { Link } from 'react-router-dom';
export function LandingPage(): JSX.Element {
  const benefits = [
    {
      icon: '/assets/shared/icons/blue/blue-heart.png',
      title: 'Clarity',
      description:
        'Contracts provide clarity and transparency to both parties involved in the transaction.',
    },
    {
      icon: '/assets/shared/icons/blue/blue-security.png',
      title: 'Protection',
      description:
        'Contracts protect both parties from potential legal disputes.',
    },
    {
      icon: '/assets/shared/icons/blue/blue-fire.png',
      title: 'Risk Management',
      description: 'Contracts help manage risks associated with the project.',
    },
    {
      icon: '/assets/shared/icons/blue/blue-chat.png',
      title: 'Communication',
      description:
        'Contracts facilitate communication between contractors and clients.',
    },
    {
      icon: '/assets/shared/icons/blue/blue-like.png',
      title: 'Professionalism',
      description:
        'Contracts demonstrate professionalism and establish credibility.',
    },
  ];
  const [navbarType, setNavbarType] = useState('');
  const {
    authState: { authUser, userInfo },
  } = useAuthContext();

  /*****************************/
  /* INTERNAL COMPONENTS */
  /****************************/
  interface HeaderProps {
    title: string;
  }

  function Header({ title }: HeaderProps) {
    return <h1 className='text-4xl sm2:text-5xl font-normal'>{title}</h1>;
  }

  interface WhyUseContractsBenefitProps {
    icon: string;
    title: string;
    description: string;
  }

  function Benefit({ icon, title, description }: WhyUseContractsBenefitProps) {
    return (
      <div className='w-full xs:w-[320px] xs2:w-[350px] xs3:w-[400px] flex flex-col gap-4 bg-gray-50 rounded-3xl p-6'>
        <div>
          <img alt='icon' src={icon} className='h-[30px]' />
        </div>
        <h1 className='text-2xl sm:text-3xl font-normal'>{title}</h1>
        <span className='text-xl sm:text-2xl text-[#6B7280] font-light'>
          {description}
        </span>
      </div>
    );
  }

  function TwoItemLayoutWithBlur() {
    return (
      <div
        className='w-full bg-scroll bg-gray-50 rounded-3xl grid grid-cols-1 md2:grid-cols-2 gap-4 md2:gap-0 items-center pt-12 px-8 md2:pt-0'
        style={{
          backgroundImage:
            'url(/assets/pages/Auth/landing-page/blurs-hiw-1.png)',
          backgroundSize: 'cover',
        }}
      >
        {/* create a contract with phone - text */}
        <div className='flex flex-row gap-4 justify-center'>
          <span className='text-2xl sm:text-3xl md:text-4xl font-normal'>
            1.
          </span>
          <div className='flex flex-col gap-2'>
            <span className='text-2xl sm:text-3xl md:text-4xl font-normal'>
              Create a contract with your phone
            </span>
            <span className='text-xl sm:text-3xl md:text-3xl text-[#6B7280] font-extralight'>
              Pull out your phone and type all the info needed for the contract
              and click send.
            </span>
          </div>
        </div>
        {/* create a contract with phone - img */}
        <div className='flex justify-center md2:pt-16'>
          <img
            alt='icon'
            src={'/assets/pages/Auth/landing-page/contract-with-phone.png'}
            className='h-auto sm:h-[400px]'
          />
        </div>
      </div>
    );
  }

  function TwoItemLayoutReverseWithBlur() {
    return (
      <div
        className='w-full bg-scroll bg-gray-50 rounded-3xl grid grid-cols-1 md2:grid-cols-2 gap-4 md2:gap-0 items-center pt-12 px-8 md2:pt-0'
        style={{
          backgroundImage:
            'url(/assets/pages/Auth/landing-page/blurs-hiw-2.png)',
          backgroundSize: 'cover',
        }}
      >
        {/* customer receives contract - text */}
        <div className='flex flex-row gap-4 justify-center'>
          <span className='text-2xl sm:text-3xl md:text-4xl font-normal'>
            2.
          </span>
          <div className='flex flex-col gap-2'>
            <span className='text-2xl sm:text-3xl md:text-4xl font-normal'>
              Customer receives contract
            </span>
            <span className='text-xl sm:text-3xl md:text-3xl text-[#6B7280] font-extralight'>
              Customer receives a copy of the contract.
            </span>
            <span className='text-xl sm:text-3xl md:text-3xl text-[#6B7280] font-extralight'>
              No more misunderstandings.
            </span>
          </div>
        </div>
        {/* customer receives contract - img */}
        <div className='flex justify-center md2:pt-16'>
          <img
            alt='icon'
            src={'/assets/pages/Auth/landing-page/laptop-with-contract.png'}
            className='h-auto sm:h-[400px]'
          />
        </div>
      </div>
    );
  }

  function ContractsOnYourPhone() {
    return (
      <div
        className='bg-scroll bg-gray-50 flex flex-col md2:flex-row py-8 md:py-12 gap4 gap-8 rounded-3xl'
        style={{
          backgroundImage:
            'url(/assets/pages/Auth/landing-page/blurs-hiw-1.png)',
          backgroundSize: 'cover',
        }}
      >
        {/* contracts with your phone text container */}
        <div className='flex-1 flex items-center pl-4 lg2:pl-12 xl:px-24'>
          <div className='w-full flex flex-col px-8 gap-4 sm:gap-8'>
            {/* contracts with your phone header */}
            <div className='flex flex-col'>
              <h1 className='text-4xl xs:text-5xl sm:text-6xl font-normal'>
                Contracts
              </h1>
              <h1 className='text-4xl xs:text-5xl sm:text-6xl font-normal'>
                with your phone
              </h1>
            </div>
            {/* contracts with your phone description */}
            <div className='flex flex-col'>
              <span className='font-normal text-base xs:text-lg sm:text-lg text-[#6B7280]'>
                Never miss a payment again!
              </span>
              <span className='font-normal text-base xs:text-lg sm:text-lg text-[#6B7280]'>
                No more paper contracts or keeping track of screenshots.
              </span>
            </div>
            {/* get started button */}
            {/* do not show button when user is authenticated */}
            <div className='flex justify-center items-center'>
              {!authUser && (
                <Link
                  className='bg-black py-2 px-8 xs:px-12 rounded-full '
                  to={navbarType == 'CONTRACTOR' ? '#' : '/select-account'}
                >
                  <span className='text-white text-base xs:text-lg'>
                    Get Started
                  </span>
                </Link>
              )}
            </div>
          </div>
        </div>
        {/* digital contract img container */}
        <div className='flex justify-center md2:justify-end'>
          <img
            alt='icon'
            src={'/assets/pages/Auth/landing-page/contracts-on-phone.png'}
            className='block md2:hidden h-[200px] xs:h-[300px] sm2:h-[350px] px-4'
          />
          <img
            alt='icon'
            src={
              '/assets/pages/Auth/landing-page/contracts-on-phone-chopped.png'
            }
            className='hidden md2:block h-[325px] md2:h-[350px] lg2:h-[400px] xl:h-[575px]'
          />
        </div>
      </div>
    );
  }

  function WhyUseContracts() {
    return (
      <div className='flex flex-col justify-center items-center pt-16 gap-12'>
        <Header title={'Why use contracts?'} />
        {/* contract benefits container */}
        <div className='flex flex-row flex-wrap items-start justify-center gap-12'>
          {benefits.map((benefit, index) => (
            <Benefit
              key={index}
              icon={benefit.icon}
              title={benefit.title}
              description={benefit.description}
            />
          ))}
        </div>
      </div>
    );
  }

  function HowItWorks() {
    return (
      <div className='flex flex-col justify-center items-center pt-16 gap-4'>
        <Header title={'How it works?'} />
        {/* Create a contract with phone - Container */}
        <TwoItemLayoutWithBlur />
        {/* Customer receives contract - Container */}
        <TwoItemLayoutReverseWithBlur />
      </div>
    );
  }

  function ContactUs() {
    return (
      <div className='w-full flex flex-col justify-center items-center gap-12'>
        <Header title={'Contact Us'} />
        <div className='w-full flex flex-col md2:flex-row justify-between items-center gap-16 md2:gap-32 xl:gap-44 p-8 md:p-16 rounded-3xl bg-gradient-to-r from-blue-500 to-[#1E47F6]'>
          {/* sub header text */}
          <div className='flex flex-col text-center'>
            <h1 className='text-white text-4xl sm:text-5xl font-extralight'>
              We are happy to assist you!
            </h1>
          </div>
          {/* contact us information */}
          <div className='flex flex-col justify-center gap-4'>
            <span className='text-xl sm:text-3xl md:text-3xl font-semibold text-white'>
              contact@slooku.com
            </span>
            <span className='text-xl sm:text-3xl md:text-3xl text-white font-extralight'>
              Feel free to send us an email.
            </span>
            <div className='flex flex-col'>
              <span className='text-xl sm:text-3xl md:text-3xl text-white font-extralight'>
                Monday - Friday
              </span>
              <span className='text-xl sm:text-3xl md:text-3xl text-white font-extralight'>
                8:00 AM - 6:00 PM CST.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /* END - INTERNAL COMPONENTS*/

  /*****************************/
  /* USE EFFECTS */
  /****************************/
  useEffect(() => {
    if (authUser && userInfo) {
      switch (userInfo.role) {
        case 'CONTRACTOR':
          setNavbarType('CONTRACTOR');
          break;
        default:
          setNavbarType('');
          break;
      }
    } else {
      setNavbarType('');
    }
  }, [authUser, userInfo]);
  /* END - USE EFFECTS */

  return (
    <div id='landing-page' className='w-full h-screen flex flex-col'>
      {navbarType == 'CONTRACTOR' && <NavbarContractor />}
      {navbarType == '' && <Navbar />}
      <main className='flex flex-col px-4 md:px-8 py-8 md:gap-16'>
        {/* Contracts on your phone container */}
        <ContractsOnYourPhone />
        {/* Why use contracts container */}
        <WhyUseContracts />
        {/* How it works container */}
        <HowItWorks />
        {/* Contact us container */}
        <div className='flex pt-8 md:pt-0'>
          <ContactUs />
        </div>
      </main>
      <Footer />
    </div>
  );
}
