import { Timestamp } from 'firebase/firestore';

export const dateToday = new Date().toLocaleDateString('en-US');

// convert seconds and nanoseconds timestamp object to mm/dd/yyy date
export function getDateFromFirestoreTimestamp(timestamp: Timestamp) {
  const { seconds, nanoseconds } = timestamp;
  const fireBaseTime = new Date(seconds * 1000 + nanoseconds / 1000000);
  return fireBaseTime.toLocaleDateString();
}

type Cost = {
  description: string;
  price: number;
  quantity: number;
};

export function CalculateTotalAmount(
  costs: Cost[],
  taxPercent: number
): string {
  // Calculate the total cost by summing up the price * quantity for each item
  const totalCost = costs.reduce(
    (total, cost) => total + cost.price * cost.quantity,
    0
  );

  // Calculate the tax amount
  const taxAmount = totalCost * (taxPercent / 100);

  // Calculate the subtotal by adding the total cost and the tax amount
  const subTotal = totalCost + taxAmount;

  // Format the subtotal as a currency string with two decimal places
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  return formatter.format(subTotal);
}
