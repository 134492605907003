import React, { useState } from 'react';
import { Footer, Navbar } from '../../../components';
import LoginForm from './Form/LoginForm';
import PasswordResetForm from './Form/PasswordResetForm';

export function LogInPage(): JSX.Element {
  const [useLoginForm, setUseLoginForm] = useState(true);

  const toggleForm = () => setUseLoginForm((value) => !value);

  return (
    <div id='login-page' className='w-full h-screen flex flex-col'>
      <Navbar />
      <main className='flex-1 flex-col px-4 md:px-8 relative'>
        {/* Image  - House Background */}
        <img
          alt='house background'
          src='/assets/login/home_background.png'
          className='rounded-2xl min-h-[770px] xs:min-h-[760px] h-full w-full object-cover opacity-[0.45]'
        />
        {/* Login Form */}
        <div className='absolute top-0 left-0 right-0 flex flex-col justify-center items-center pt-[5.5rem]'>
          {useLoginForm ? (
            <LoginForm handleToggleClick={toggleForm} />
          ) : (
            <PasswordResetForm handleToggleClick={toggleForm} />
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
}
