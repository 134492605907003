import React from 'react';
import { Footer, HomeNavbar } from '../../../components';

export function TermsOfServicePage(): JSX.Element {
  return (
    <div
      id='terms-and-conditions-page'
      className='w-full h-screen flex flex-col'
    >
      <HomeNavbar />
      <main className='flex-1 flex-col px-4 md:px-8'>
        <div className='w-full h-full flex flex-col gap-6 mt-12 md:mt-16'>
          <h1 className='font-medium text-xl text-gray-800'>TERMS OF USE</h1>
          <span className='text-base text-gray-800'>
            Slooku provides a contract management system to its clients. By
            using our services, you agree to the following terms:
          </span>
          <h2 className='font-medium text-lg text-gray-800'>1. Payment:</h2>
          <span className='text-base text-gray-800'>
            Payment is due upon completion of the project. We accept cash,
            check, and credit card payments.
          </span>
          <h2 className='font-medium text-lg text-gray-800'>
            2. Changes to the Project:
          </h2>
          <span className='text-base text-gray-800'>
            Any changes to the project must be agreed upon in writing by both
            parties.
          </span>
          <h2 className='font-medium text-lg text-gray-800'>3. Delays:</h2>
          <span className='text-base text-gray-800'>
            We are not responsible for delays caused by factors beyond our
            control, including but not limited to weather, labor strikes, or
            technical issues.
          </span>
          <h2 className='font-medium text-lg text-gray-800'>4. Warranty:</h2>
          <span className='text-base text-gray-800'>
            We provide a one-year warranty on all workmanship.
          </span>
          <h2 className='font-medium text-lg text-gray-800'>5. Liability:</h2>
          <span className='text-base text-gray-800'>
            We are not liable for any damages or injuries that occur as a result
            of using our services.
          </span>
          <h2 className='font-medium text-lg text-gray-800'>6. Termination:</h2>
          <span className='text-base text-gray-800'>
            Either party may terminate the contract at any time with written
            notice.
          </span>
          <h2 className='font-medium text-lg text-gray-800'>
            7. Dispute Resolution:
          </h2>
          <span className='text-base text-gray-800'>
            Any disputes arising from this contract will be resolved through
            arbitration.
          </span>
          <h2 className='font-medium text-lg text-gray-800'>
            8. Governing Law:
          </h2>
          <span className='text-base text-gray-800'>
            This contract shall be governed by the laws of the State of Texas.
          </span>
          <h2 className='font-medium text-lg text-gray-800'>
            9. Entire Agreement:
          </h2>
          <span className='text-base text-gray-800'>
            This contract constitutes the entire agreement between the parties
            and supersedes all prior agreements and understandings, whether
            written or oral.
          </span>
        </div>
      </main>
      <Footer />
    </div>
  );
}
