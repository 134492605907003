import React from 'react';

type Props = {
  children: React.ReactNode;
};

export function FormViewLayout({ children }: Props): JSX.Element {
  return (
    <main className='flex justify-center pt-16 md:pt-24 pb-8 px-4 md:px-8'>
      {children}
    </main>
  );
}
