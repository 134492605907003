import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';

export default function ContractorSearchBar(): JSX.Element {
  return (
    <button className='flex h-[54px] w-full sm2:w-[500px] sm2:border sm2:border-slate-300 rounded-full shadow-[0_1px_6px_rgba(8,_112,_184,_0.25)] sm2:shadow-[0_1px_6px_rgba(8,_112,_184,_0.10)]'>
      <div className='flex flex-row items-center justify-between w-full h-full ml-3 mr-4 sm:mr-1 gap-3'>
        {/* Search Services - Text */}
        <div className='flex flex-row items-center gap-1 xs2:gap-0'>
          <div className='flex xs2:hidden'>
            <img
              src='/assets/shared/icons/magnifying-glass.svg'
              className='h-4'
            />
          </div>
          <span className='flex xs:hidden text-textInpugLightGrey'>Search</span>
          <span className='hidden xs:flex xs2:ml-4 sm2:hidden text-textInpugLightGrey'>
            Search service
          </span>
          <span className='hidden sm2:flex ml-4 text-textInpugLightGrey'>
            Search for your service
          </span>
        </div>
        {/* Zipcode with Location and Yellow Search Button */}
        <div className='hidden xs2:flex flex-row items-center gap-4'>
          {/* Zipcode with Location */}
          <div className='flex flex-row gap-2 items-center'>
            {/* Pipe Division */}
            <span className='text-2xl font-thin'>|</span>
            <div className='flex flex-row items-center gap-1'>
              <FaMapMarkerAlt color='#343B53' />
              <span className='text-navTabDarkGrey'>Dallas</span>
            </div>
          </div>
          {/* Yello Search Button */}
          <div className='hidden sm:flex bg-slookuYellow rounded-full px-4 py-2'>
            Search
          </div>
        </div>
      </div>
    </button>
  );
}
