import React from 'react';
import InputWithBottomBorder from '../../../../../components/InputWithBottomBorder/InputWithBottomBorder';
import SaveButton from '../Shared/SaveButton/SaveButton';

function HomeAddress(): JSX.Element {
  const HOME_ADDRESS = 'Home Address';

  return (
    <form className='flex flex-col gap-4'>
      {/* Header*/}
      <div className='flex'>
        {/* Title */}
        <div className='text-2xl font-bold'>
          <h1>{HOME_ADDRESS}</h1>
        </div>
      </div>
      {/* Note to contractors */}
      <div className='flex flex-row gap-1'>
        <div className='flex flex-col shrink-0 mt-[2px]'>
          <img
            className='w-[20px] h-[20px]'
            src='/assets/shared/icons/ExclamationCircle.svg'
            alt='exclamation icon'
          />
        </div>
        <div className='flex flex-col'>
          <span className='text-[#676d73] opacity-80'>
            Note: We need your address to connect you with contractors in your
            area.
          </span>
        </div>
      </div>
      {/* Input Grid */}
      <div className='input-grid grid grid-cols-1 sm2:grid-cols-2 gap-4'>
        <InputWithBottomBorder
          inputTitle={'Street'}
          iconSource={'/assets/shared/icons/LocationMarker.svg'}
          placeholder={'#123 Street Name'}
          type='text'
          aria-label={'Input for Street'}
        />
        <InputWithBottomBorder
          inputTitle={'City'}
          placeholder={'Dallas'}
          type='text'
          aria-label={'Input for City'}
        />
        <InputWithBottomBorder
          inputTitle={'State'}
          placeholder={'Texas'}
          type='text'
          aria-label={'Input for State'}
        />
        <InputWithBottomBorder
          inputTitle={'Zipcode'}
          placeholder={'75000'}
          type='text'
          aria-label={'Input for Zipcode'}
        />
      </div>
      {/* Save Changes Button */}
      <div className='flex w-full justify-center mt-2'>
        <SaveButton />
      </div>
    </form>
  );
}

export default HomeAddress;
