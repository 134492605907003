import { Timestamp } from 'firebase/firestore';
import { User } from 'firebase/auth';

export type SignUpDataForm = {
  email: string;
  password: string;
  confirm_password: string;
  tos_privacy_checkbox: boolean;
};

export type SignInDataForm = {
  email: string;
  password: string;
};

export interface company {
  name: string;
  address: address;
  contact: contact;
}

export interface customer {
  firstName: string;
  lastName: string;
  address: address;
  contact: contact;
}

export interface address {
  street?: string;
  city?: string;
  state?: string;
  zipcode?: string;
}

export interface contact {
  phoneNumber: string;
  email: string;
}

export interface StandardEstimateDataForm {
  type: string;
  createdAt: Timestamp;
  jobDetails: string;
  taxPercent: number;
  company: company;
  customer: customer;
  costs: {
    description: string;
    quantity: number;
    price: number;
  }[];
}

export interface StandardEstimateDTO {
  id: string;
  data: StandardEstimateDataForm;
}

export const StandardEstimateContractType = 'StandardEstimate';
/* END - StandardEstimateDataForm */

/* HelpAndSupportDataForm */
export type HelpAndSupportDataForm = {
  name: string;
  email: string;
  phoneNumber: string;
  helpMessage: string;
};
/* END - HelpAndSupportDataForm */

/* FIREBASE */
// type for firebase authentication user
export type AuthUser = User;

export type Role = 'CONTRACTOR' | 'CUSTOMER' | 'DEFAULT';

export type CustomerInfo = {
  id: string;
  username: string;
  email: string;
  role: Role;
  createdAt: Timestamp;
  updatedAt?: Timestamp;
};

export type ContractorInfo = {
  id: string;
  username: string;
  email: string;
  role: Role;
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  company?: company;
};
/* END - FIREBASE */
