import React from 'react';

export function HeaderAndContentSkeleton() {
  return (
    <div className='flex flex-col w-full animate-pulse gap-6'>
      {/* header */}
      <div className='h-16 sm2:h-20 bg-gray-300 rounded w-full'></div>
      {/* content */}
      <div className='h-12 bg-gray-300 rounded w-full'></div>
      <div className='h-12 bg-gray-300 rounded w-full'></div>
      <div className='h-12 bg-gray-300 rounded w-full'></div>
      <div className='h-12 bg-gray-300 rounded w-full'></div>
    </div>
  );
}
