import React from 'react';
import { Link } from 'react-router-dom';
import { INavbarLinks } from '../Types/INavbarLinks';
import '../../Navbar.css';
import { useAuthenticate } from '../../../../hooks';
import { useAuthContext } from '../../../../state/auth-context';

type DropdownNavigationProps = {
  showDropdownMenu: boolean;
  onClick: () => void;
  navLinks: INavbarLinks[];
};

export default function DropdownNavigation({
  showDropdownMenu,
  onClick,
  navLinks,
}: DropdownNavigationProps): JSX.Element {
  const {
    authState: { authUser },
  } = useAuthContext();
  const { signOut } = useAuthenticate();

  const handleSignOut = async () => {
    await signOut();
  };

  return (
    <ul
      className={
        'absolute z-20 right-0 w-[300px] max-w-[80vw] mt-2 px-2 bg-white border border-slate-200 md:border-none rounded-md navigation-dropdown-menu-shadow ' +
        (showDropdownMenu ? 'block ' : ' hidden')
      }
    >
      <div className='flex flex-col py-4'>
        {/* last item in array will be a button others are plan columns */}
        {navLinks &&
          navLinks.length != 0 &&
          navLinks.map((link, index) =>
            index !== navLinks.length - 1 ? (
              <Link key={index} to={link.path} onClick={onClick}>
                <li className='rounded py-2 px-4 block text-navTabDarkGrey hover:bg-slate-100'>
                  {link.name}
                </li>
              </Link>
            ) : (
              <div key={index} className='flex justify-center'>
                <Link to={link.path} onClick={onClick}>
                  <li>
                    <button
                      type='button'
                      className='cursor-pointer bg-black hover:bg-black/80 text-base text-white px-10 py-2 mt-2 rounded-3xl'
                      onClick={authUser ? handleSignOut : undefined}
                    >
                      {link.name}
                    </button>
                  </li>
                </Link>
              </div>
            )
          )}
      </div>
    </ul>
  );
}
