import React from 'react';
import { useAuthenticate } from '../../../../../../hooks';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../../../../state/auth-context';

export function AccountReset() {
  const CHANGE_PASSWORD = 'Change Password';
  const { resetPassword } = useAuthenticate();
  const {
    authState: { authUser, userInfo },
  } = useAuthContext();

  const onPasswordResetClick = async () => {
    if (authUser && userInfo) {
      const userEmail = userInfo.email;
      const toastPassReset = toast('Sending password reset link...', {
        type: 'info',
      });

      try {
        await resetPassword({
          email: userEmail,
        });
        toast.update(toastPassReset, {
          render: 'Password reset link sent! Check your email.',
          type: 'success',
          autoClose: 5000,
        });
      } catch (error) {
        toast.update(toastPassReset, {
          render: 'Error resetting password. Try again later.',
          type: 'error',
          autoClose: 5000,
        });
      }
    }
  };
  return (
    <div className='flex flex-col gap-6'>
      {/* Header */}
      <div className='flex'>
        {/* Title */}
        <div className='text-2xl font-bold'>
          <h1>{CHANGE_PASSWORD}</h1>
        </div>
      </div>
      {/* Forgot Password */}
      <div className='flex'>
        <button
          className='text-lg text-[#3F82ED] hover:underline hover:cursor-pointer pr-2'
          onClick={onPasswordResetClick}
        >
          Click for Password Reset Link
        </button>
      </div>
      {/* Pass requirements */}
      <div className='flex flex-col gap-2'>
        <div className='flex flex-row gap-1'>
          <div className='flex flex-col shrink-0 mt-[2px]'>
            <img
              className='w-[20px] h-[20px]'
              src='/assets/shared/icons/ExclamationCircle.svg'
              alt='exclamation icon'
            />
          </div>
          <div className='flex flex-col'>
            <span className='text-[#676d73] opacity-80'>
              Note: Your new password should contain all of the following:
            </span>
          </div>
        </div>
        <div className='grid grid-cols-1 sm2:grid-cols-2 gap-2'>
          <span className='text-sm sm2:text-base text-[#676d73] opacity-80'>
            One special character (!@#$%^&*)
          </span>
          <span className='text-sm sm2:text-base text-[#676d73] opacity-80'>
            One number (0-9)
          </span>
          <span className='text-sm sm2:text-base text-[#676d73] opacity-80'>
            6 characters minimum
          </span>
          <span className='text-sm sm2:text-base text-[#676d73] opacity-80'>
            One letter (a-z)
          </span>
        </div>
      </div>
    </div>
  );
}
