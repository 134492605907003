import React from 'react';
import NavbarContainer from '../shared/NavbarContainer/NavbarContainer';
import '../Navbar.css';
import NavbarContent from '../shared/NavbarContent/NavbarContent';

/*******************************/
/* DO NOT DELETE THIS COMMENT * /
/* THIS HEADER IS USED FOR UNAUTHENTICATED USERS */
/*******************************/
export default function Navbar(): JSX.Element {
  return (
    <NavbarContainer>
      <NavbarContent />
    </NavbarContainer>
  );
}
