import { db } from '../../../../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { contractorsCollection } from '../../../../shared/constants';
import { company, ContractorInfo } from '../../../../types';

export const GetContractorInformation = async (contractorId: string) => {
  const docRef = doc(db, `${contractorsCollection}/${contractorId}`);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const contractorInfo = docSnap.data() as ContractorInfo;
      return contractorInfo;
    } else {
      // document does not exists
      return null;
    }
  } catch {
    // error occurred while getting document
    return null;
  }
};

export const UpdateContractorCompanyInformation = async (
  contractorId: string,
  companyDataUpdate: company
) => {
  const docRef = doc(db, `${contractorsCollection}/${contractorId}`);
  try {
    // add company data to the contractor document
    await updateDoc(docRef, {
      company: companyDataUpdate,
    });
    return true;
  } catch {
    return false;
  }
};
