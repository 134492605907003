import React from 'react';
import { Footer, Navbar } from '../../../components';
import { ContractorAccountCard } from './ContractorAccountCard/ContractorAccountCard';

export function SelectAccountPage(): JSX.Element {
  return (
    <div id='select-account-page' className='w-full h-screen flex flex-col'>
      <Navbar />
      <main className='flex-1 flex-col px-4 md:px-8'>
        <div className='w-full h-full flex flex-col md:flex-row gap-6'>
          {/* Contractor Account */}
          <ContractorAccountCard />
        </div>
      </main>
      <Footer />
    </div>
  );
}
