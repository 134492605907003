import React from 'react';
import InputWithBottomBorderPass from '../../../../../components/InputWithBottomBorderPass/InputWithBottomBorderPass';
import SaveButton from '../Shared/SaveButton/SaveButton';

const AccountReset = () => {
  const CHANGE_PASSWORD = 'Change Password';

  return (
    <form className='flex flex-col gap-4'>
      {/* Header */}
      <div className='flex'>
        {/* Title */}
        <div className='text-2xl font-bold'>
          <h1>{CHANGE_PASSWORD}</h1>
        </div>
      </div>
      {/* Forgot Password */}
      <div className='flex'>
        <div className='text-lg text-[#3F82ED] hover:underline hover:cursor-pointer'>
          Forgot Password
        </div>
      </div>
      {/* Input Grid */}
      <div className='input-grid grid grid-cols-1 sm2:grid-cols-2 gap-4'>
        <div className='sm2:col-start-1 sm2:col-end-1 sm2:col-span-2'>
          <InputWithBottomBorderPass
            inputTitle={'Current Password'}
            placeHolder={'*****'}
            inputLabel={'Current Password'}
          />
        </div>
        <div className='sm2:col-start-1 sm2:col-end-1'>
          <InputWithBottomBorderPass
            inputTitle={'New Password'}
            placeHolder={'*****'}
            inputLabel={'New Password'}
          />
        </div>
        <div className='sm2:col-start-2 sm2:col-end-2'>
          <InputWithBottomBorderPass
            inputTitle={'Confirm New Password'}
            placeHolder={'*****'}
            inputLabel={'Confirm New Password'}
          />
        </div>
      </div>
      {/* Pass requirements */}
      <div className='grid grid-cols-1 sm2:grid-cols-2 gap-2 sm2:gap-4'>
        <span className='text-sm sm2:text-base text-[#A6AFB8]'>
          One special character (!@#$%^&*)
        </span>
        <span className='text-sm sm2:text-base text-[#A6AFB8]'>
          One number (0-9)
        </span>
        <span className='text-sm sm2:text-base text-[#A6AFB8]'>
          8 characters minimum
        </span>
        <span className='text-sm sm2:text-base text-[#A6AFB8]'>
          One letter (a-z)
        </span>
      </div>
      {/* Save Changes Button */}
      <div className='flex w-full justify-center'>
        <SaveButton />
      </div>
    </form>
  );
};

export default AccountReset;
