import React from 'react';

export function StatusMessageWarning({ message }: { message: string }) {
  return (
    <div className='flex flex-col w-full bg-amber-100 p-4 rounded-xl text-center'>
      <p className='text-base sm2:text-lg font-light text-gray-500'>
        {message}
      </p>
    </div>
  );
}
