import React from 'react';
import { Footer, HomeNavbar } from '../../../components';

export function PrivacyPolicyPage(): JSX.Element {
  return (
    <div id='privacy-policy-page' className='w-full h-screen flex flex-col'>
      <HomeNavbar />
      <main className='flex-1 flex-col px-4 md:px-8'>
        <div className='w-full h-full flex flex-col gap-6 mt-12 md:mt-16'>
          <h1 className='font-medium text-xl text-gray-800'>Privacy Policy</h1>
          <span className='text-base text-gray-800'>
            Slooku is committed to protecting the privacy of its clients and
            users of its website. This Privacy Policy outlines how we collect,
            use, and protect your personal information.
          </span>
          <h2 className='font-medium text-lg text-gray-800'>
            Information Collection and Use
          </h2>
          <span className='text-base text-gray-800'>
            We collect personal information when you provide it to us, such as
            when you fill out a form on our website or contact us via email. The
            information we collect may include your name, email address, phone
            number, and other contact information.
          </span>
          <span className='text-base text-gray-800'>
            We use this information to respond to your inquiries, provide you
            with services, and improve our website. We may also use your
            information to send you marketing communications, but you can
            opt-out of these communications at any time.
          </span>
          <h2 className='font-medium text-lg text-gray-800'>
            Information Collection and Use
          </h2>
          <span className='text-base text-gray-800'>
            We do not sell, trade, or rent your personal information to third
            parties. We may share your information with our service providers
            who assist us in providing services to you. We may also share your
            information if required by law or to protect our rights or property.
          </span>
          <h2 className='font-medium text-lg text-gray-800'>Security </h2>
          <span className='text-base text-gray-800'>
            We take reasonable measures to protect your personal information
            from unauthorized access, use, or disclosure. However, no method of
            transmission over the internet or electronic storage is 100% secure,
            so we cannot guarantee absolute security.
          </span>
          <h2 className='font-medium text-lg text-gray-800'>
            Changes to this Privacy Policy
          </h2>
          <span className='text-base text-gray-800'>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </span>
          <h2 className='font-medium text-lg text-gray-800'>Contact Us </h2>
          <span className='text-base text-gray-800'>
            If you have any questions about this Privacy Policy, please contact
            us at [contact email].
          </span>
        </div>
      </main>
      <Footer />
    </div>
  );
}
