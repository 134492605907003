import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

type NavbarContainerProps = {
  children: ReactNode;
};

export default function NavbarContainer({
  children,
}: NavbarContainerProps): JSX.Element {
  const locationFull = useLocation();
  const currentLocation = locationFull.pathname;

  return (
    <div
      className={
        'w-full h-auto pt-4 px-8 md:px-16 pb-4 border-[1px] ' +
        (currentLocation == '/select-account' || currentLocation == '/log-in'
          ? ' border-transparent '
          : ' border-[#E5E7EB] ')
      }
    >
      <div className='navbar-content-container flex w-full h-[54px] items-center justify-between'>
        {children}
      </div>
    </div>
  );
}
