import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  dataText: string | number;
  isLargeColumn?: boolean;
};

export default function TableColDataLink({
  isLargeColumn,
  dataText,
}: Props): JSX.Element {
  return (
    <td
      className={
        'px-6 py-4 text-base text-[#509ef6] whitespace-nowrap overflow-hidden overflow-ellipsis ' +
        (isLargeColumn ? 'max-w-[360px]' : 'max-w-[180px]')
      }
    >
      <Link to={`${dataText}`}>{dataText}</Link>
    </td>
  );
}
