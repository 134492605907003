import React from 'react';
import { INavbarLinks } from '../../Types/INavbarLinks';
import ContractorSearchBar from '../../ContractorSearchBar/ContractorSearchBar';
import HamburgerMenuWithDropdownNavigation from '../../HamburgerMenuWithDropdownNavigation/HamburgerMenuWithDropdownNavigation';
import SlookuLogoTitle from '../../SlookuLogoTitle/SlookuLogoTitle';

type NavbarContentSearchProps = {
  navLinks: INavbarLinks[];
};

export default function NavbarContentSearch({
  navLinks,
}: NavbarContentSearchProps): JSX.Element {
  return (
    <>
      {/* Logo-Title and Desktop Navigation link */}
      <div className='hidden md2:flex flex-row gap-8'>
        {/* Logo-Title */}
        <SlookuLogoTitle />
      </div>
      {/* Search Bar - margin is used to prevent touching burger menu for small screens */}
      <div className='flex w-full md:w-auto h-auto mr-4 sm2:mr-0'>
        <ContractorSearchBar />
      </div>
      {/* Hamburger Menu with Dropdown Navigation */}
      <HamburgerMenuWithDropdownNavigation navLinks={navLinks} />
    </>
  );
}
